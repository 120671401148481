import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";

import LoadingButton from "@mui/lab/LoadingButton";

import { Customer, CustomerSchema } from "@/pages/Private/pages/ProductLinks/schema/customers";

interface InviteUserModalProps {
	presetCustomer: Partial<Customer>;
	setCustomer: (customer: Customer) => void;
}

export const CustomerForm: FunctionComponent<InviteUserModalProps> = ({
	presetCustomer,
	setCustomer,
}) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Customer>({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			...presetCustomer,
		},
		resolver: zodResolver(CustomerSchema),
	});

	const onSubmit = async (values: Customer) => {
		try {
			if (setCustomer) {
				setCustomer(values);
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Stack spacing={1}>
			<Typography variant="h4">Bitte hinterlege deine Kontaktdaten</Typography>
			<TextField
				error={!!errors.firstName?.message}
				label={t("basics.firstName")}
				placeholder={t("basics.firstName")}
				{...register("firstName")}
			/>

			<TextField
				error={!!errors.lastName?.message}
				label={t("basics.lastName")}
				placeholder={t("basics.lastName")}
				{...register("lastName")}
			/>

			<TextField
				error={!!errors.email?.message}
				label={t("basics.email")}
				placeholder={t("basics.email")}
				{...register("email")}
			/>

			{/* {getAllErrors(error, formErrors).length
				? renderErrorMessages(getAllErrors(error, formErrors))
				: null} */}

			<LoadingButton
				loading={false}
				sx={{
					backgroundColor: theme.palette.primary.main,
					"&:hover": {
						backgroundColor: theme.palette.primary.dark,
					},
				}}
				variant="contained"
				onClick={handleSubmit(onSubmit)}
			>
				{t("basics.confirm")}
			</LoadingButton>
		</Stack>
	);
};
