import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControlLabel, MenuItem, Switch, TextField } from "@mui/material";
import { Stack } from "@mui/system";

import { LoadingOverlay, Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useGetProductQuery } from "@/pages/Private/redux/product/product.api";

import { useCreateSubscriptionMutation } from "@/pages/Private/redux/subscription/subscription.api";
import { IntervalEnum } from "@/enum/subscription-enum";
import { DEFAULT } from "@/constants";

import { CreateSubscriptionRequest, CreateSubscriptionSchema } from "../schema/create-subscription";
import { Product } from "../../Products/schema/get-products";

interface createSubscriptionModalProps {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CreateSubscriptionModal: FunctionComponent<createSubscriptionModalProps> = ({
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`subscription.${key}`);
	const [createSubscription, { isLoading, error }] = useCreateSubscriptionMutation();

	const { data: products } = useGetProductQuery({
		page: 1,
		limit: 50,
	});

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<CreateSubscriptionRequest>({
		defaultValues: {
			productId: 0,
			name: undefined,
			onetimeAmount: undefined,
			installmentAmount: undefined,
			employees: undefined,
			cycles: undefined,
			invoice: false,
			interval: undefined,
		},
		resolver: zodResolver(CreateSubscriptionSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: CreateSubscriptionRequest) => {
		if (!values.installmentAmount) {
			values.installmentAmount = 0;
		}

		if (!values.cycles) {
			values.cycles = 0;
		}

		if (!values.employees) {
			values.employees = 0;
		}

		try {
			await createSubscription(values).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			{products?.data ? (
				<Stack spacing={2}>
					<TextField
						error={!!errors.name?.message}
						label={ts("name")}
						placeholder={ts("name")}
						{...register("name")}
					/>

					<TextField
						error={!!errors.onetimeAmount?.message}
						label={ts("onetimeAmount")}
						placeholder={ts("onetimeAmount")}
						{...register("onetimeAmount", {
							validate: (v) => {
								if (!v && !getValues("installmentAmount")) {
									return false;
								}

								return true;
							},
							setValueAs: (v) => (v === "" ? undefined : parseInt(v, 10)),
						})}
					/>

					<TextField
						error={!!errors.installmentAmount?.message}
						label={ts("installmentAmount")}
						placeholder={ts("installmentAmount")}
						{...register("installmentAmount", {
							validate: (v) => {
								if (!v && !getValues("onetimeAmount")) {
									return false;
								}

								return true;
							},
							setValueAs: (v) => (v === "" ? undefined : parseInt(v, 10)),
						})}
					/>

					<TextField
						error={!!errors.employees?.message}
						label={ts("employees")}
						placeholder={ts("employees")}
						{...register("employees", {
							setValueAs: (v) => (v === "" ? undefined : parseInt(v, 10)),
						})}
					/>

					<TextField
						error={!!errors.cycles?.message}
						label={ts("cycles")}
						placeholder={ts("cycles")}
						{...register("cycles", {
							validate: (v) => {
								if (!v && getValues("installmentAmount")) {
									return false;
								}

								return true;
							},
							setValueAs: (v) => {
								return v === "" ? undefined : parseInt(v, 10);
							},
						})}
					/>

					<TextField
						select
						error={!!errors.interval?.message}
						label={ts("interval")}
						placeholder={ts("interval")}
						{...register("interval", {
							validate: (v) => {
								if (!v && getValues("installmentAmount")) {
									return false;
								}

								return true;
							},
							setValueAs: (v) => {
								return v === "" ? undefined : parseInt(v, 10);
							},
						})}
					>
						<MenuItem disabled value={DEFAULT}>
							{ts("intervalPlaceholder")}
						</MenuItem>

						<MenuItem key={`intervalkey-${IntervalEnum.DAY}`} value={IntervalEnum.DAY}>
							{ts(IntervalEnum.DAY)} {ts("installmentAmount")}
						</MenuItem>

						<MenuItem key={`intervalkey-${IntervalEnum.WEEK}`} value={IntervalEnum.WEEK}>
							{ts(IntervalEnum.WEEK)} {ts("installmentAmount")}
						</MenuItem>

						<MenuItem key={`intervalkey-${IntervalEnum.MONTH}`} value={IntervalEnum.MONTH}>
							{ts(IntervalEnum.MONTH)} {ts("installmentAmount")}
						</MenuItem>

						<MenuItem key={`intervalkey-${IntervalEnum.YEAR}`} value={IntervalEnum.YEAR}>
							{ts(IntervalEnum.YEAR)} {ts("installmentAmount")}
						</MenuItem>
					</TextField>

					<FormControlLabel
						control={<Switch color="primary" {...register("invoice")} />}
						label={ts("asInvoice")}
					/>

					<TextField
						select
						error={!!errors.productId?.message}
						label={ts("productName")}
						placeholder={ts("Product")}
						{...register("productId")}
					>
						<MenuItem disabled value={DEFAULT}>
							{ts("placeholder")}
						</MenuItem>

						{products?.data.map((product: Product) => (
							<MenuItem key={product.id} value={product.id}>
								{product.name}
							</MenuItem>
						))}
					</TextField>

					{getAllErrors(error, formErrors).length
						? renderErrorMessages(getAllErrors(error, formErrors))
						: null}
				</Stack>
			) : (
				<LoadingOverlay />
			)}
		</Modal>
	);
};
