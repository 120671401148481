import { FunctionComponent } from "react";

import { Box, Container, Stack, Typography } from "@mui/material";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/system";

export const OrderFailed: FunctionComponent = () => {
	const theme = useTheme();

	return (
		<Container>
			<Stack alignItems={"center"} direction="column" justifyContent={"center"}>
				<Box mb={6} mt={6}>
					<FontAwesomeIcon color={theme.palette["error"].main} icon={faCircleXmark} size="9x" />
				</Box>
				<Box justifyContent={"center"} textAlign={"center"}>
					<Typography variant="h1">Zahlung konnte nicht erfolgreich initiiert werden...</Typography>
				</Box>
			</Stack>
		</Container>
	);
};
