import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useResetPasswordMutation } from "@/pages/Public/redux/auth.api";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { AuthError } from "@/components";

import { ForgotPasswordSchema, ForgotPasswordType } from "./schema/forgot-password";

export const ForgotPassword: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`forgotPassword.${key}`);
	const [resetPassword, { isLoading, isSuccess, error }] = useResetPasswordMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotPasswordType>({
		defaultValues: {
			email: "",
		},
		resolver: zodResolver(ForgotPasswordSchema),
	});

	const formErrors = Object.values(errors).map((error) => error.message) as ERROR_TYPE[];

	const onSubmit = async (values: ForgotPasswordType) => {
		try {
			await resetPassword(values).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Box>
			<Typography mb={4} variant="h6">
				{ts("title")}
			</Typography>

			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				{!isSuccess && (
					<TextField fullWidth label={t("basics.email")} variant="filled" {...register("email")} />
				)}

				<Box mt={2}>
					{isSuccess ? (
						<Typography component="p" mb={4} variant="body2">
							{t("forgotPassword.submittedFeedbackMessage")}
						</Typography>
					) : (
						<LoadingButton
							loading={isLoading}
							size="large"
							sx={{ width: "100%" }}
							type="submit"
							variant="contained"
						>
							{t("forgotPassword.forgotPasswordCta")}
						</LoadingButton>
					)}
				</Box>
			</Box>

			{getAllErrors(error, formErrors).length ? (
				<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
			) : null}
		</Box>
	);
};
