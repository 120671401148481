import { Outlet } from "react-router-dom";
import { AppBar, Box, Container, Stack } from "@mui/material";

import TKCLogo from "@/assets/images/TKC_Shortlogo_digital_black.svg";

export const PlinkLayout = () => {
	return (
		<Box
			id="portal-auth"
			sx={{
				width: "100%",
				minHeight: "100vh",
			}}
		>
			<AppBar
				color="transparent"
				position="static"
				style={{
					height: "60px",
					background:
						"radial-gradient(circle, #e6caa6, #9f785f 99%), linear-gradient(225deg, #aa8267 16%, #f1d3ac)",
					justifyContent: "center",
				}}
			>
				<Container>
					<Stack
						alignItems={"top"}
						direction="row"
						justifyContent={"space-between"}
						sx={{ width: "100%" }}
					>
						<Stack direction="row" gap={1}>
							<Box component="img" height={"46px"} src={TKCLogo} />
						</Stack>
					</Stack>
				</Container>
			</AppBar>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
				}}
			>
				{/* <Box
				alt="Background image"
				component="img"
				src={HeroImage}
				sx={{
					width: "100%",
					height: "100%",
					position: "fixed",
					inset: 0,
					objectFit: "cover",
				}}
			/> */}

				<Box
					px={7}
					py={5}
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
};
