import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import { Icon, PermissionCheck } from "@/components";

import { PermissionRoles } from "@/enum";

import { ProductsTable } from "./components/ProductsTable";
import { CreateProductModal } from "./components/CreateProductModal";

export const Products: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`product.${key}`);

	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

	return (
		<>
			<Stack direction="row" justifyContent="space-between" mb={4}>
				<Typography variant="h2">{ts("title")}</Typography>
				<PermissionCheck requiredPermissions={[PermissionRoles.CREATE_PRODUCT]}>
					<Button
						size="small"
						startIcon={<Icon icon={faPlus} />}
						variant="contained"
						onClick={() => setIsInviteModalOpen(true)}
					>
						{ts("create.button")}
					</Button>
				</PermissionCheck>
			</Stack>

			<ProductsTable />

			{isInviteModalOpen && (
				<CreateProductModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} />
			)}
		</>
	);
};
