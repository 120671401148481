import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { Crop } from "react-image-crop";
import { Box, Typography } from "@mui/material";
import { CheckRounded, DeleteOutlineRounded, InsertPhotoOutlined } from "@mui/icons-material";

import { ImageCropper } from "./ImageCropper";

interface ImageUploaderProps {
	border?: boolean;
	setCurrentImgUrl: (url: string) => void;
	currentImgUrl?: string;
}

export const ImageUploader: FunctionComponent<ImageUploaderProps> = ({
	border,
	setCurrentImgUrl,
	currentImgUrl,
}) => {
	const { t } = useTranslation();
	// The file that is uploaded in the File Uploader
	const [uploadedFile, setUploadedFile] = useState<null | File>(null);

	// this state is used to store the current fileUrl that is the current uploaded image
	const [fileUrl, setFileUrl] = useState<null | string>(null);

	// Crop state for Cropper
	const [image, setImage] = useState<HTMLImageElement | null>(null);
	const [crop, setCrop] = useState<Crop>();

	const handleSaveCrop = () => {
		if (image && crop) {
			const canvas = document.createElement("canvas");
			const scaleX = image.naturalWidth / image.width;
			const scaleY = image.naturalHeight / image.height;

			canvas.width = crop.width;
			canvas.height = crop.height;
			const ctx = canvas.getContext("2d");

			if (ctx) {
				const pixelRatio = window.devicePixelRatio;

				canvas.width = crop.width * pixelRatio;
				canvas.height = crop.height * pixelRatio;
				ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
				ctx.imageSmoothingQuality = "high";

				ctx.drawImage(
					image,
					crop.x * scaleX,
					crop.y * scaleY,
					crop.width * scaleX,
					crop.height * scaleY,
					0,
					0,
					crop.width,
					crop.height
				);

				// Converting to base64
				const base64Image = canvas.toDataURL("image/png");

				setCurrentImgUrl(base64Image);
				setCrop(undefined);
				setFileUrl(null);
			}
		}
	};

	const resetFile = useCallback(() => {
		setUploadedFile(null);
		setFileUrl(null);
		setCrop(undefined);
		setCurrentImgUrl("");
	}, [setCurrentImgUrl]);

	useEffect(() => {
		if (uploadedFile) {
			const reader = new FileReader();

			reader.readAsDataURL(uploadedFile);
			reader.onload = () => setFileUrl(reader.result as string);
		}
	}, [uploadedFile]);

	return (
		<Box
			bgcolor="white"
			borderRadius={2}
			overflow="hidden"
			position="relative"
			sx={{
				...(border ? { border: "1px solid", borderColor: "#E2E2E2" } : { boxShadow: 1 }),
			}}
			width="100%"
		>
			{fileUrl ? (
				<ImageCropper crop={crop} image={fileUrl} setCrop={setCrop} setImage={setImage} />
			) : !currentImgUrl ? (
				<FileUploader
					handleChange={(file: File) => setUploadedFile(file)}
					name="file"
					types={["JPG", "JPEG", "PNG"]}
				>
					<Box
						alignItems="center"
						color="gray600.main"
						display="flex"
						flexDirection="column"
						height="100%"
						justifyContent="center"
						sx={{ cursor: "pointer" }}
						textAlign="center"
					>
						<InsertPhotoOutlined sx={{ width: 40, height: "auto" }} />
						<Typography fontSize={14} maxWidth={290} mt={2.75}>
							{t("basics.dragAndDropImage")}
						</Typography>
					</Box>
				</FileUploader>
			) : (
				<Box alignItems="center" display="flex" justifyContent="center">
					<img
						alt=""
						src={currentImgUrl}
						style={{ width: "auto", maxHeight: "100%", maxWidth: "100%" }}
					/>
				</Box>
			)}

			{(fileUrl || currentImgUrl) && (
				<Box bottom={8} color="#ffbe3a" display="flex" position="absolute" right={8}>
					{crop && (
						<Box
							bgcolor="green.main"
							borderRadius={1}
							boxShadow={1}
							height={28}
							mr={1}
							p="4px 6px"
							sx={{ cursor: "pointer" }}
							onClick={handleSaveCrop}
						>
							<CheckRounded />
						</Box>
					)}
					<Box
						bgcolor="red.main"
						borderRadius={1}
						boxShadow={1}
						height={28}
						p="4px 6px"
						sx={{ cursor: "pointer" }}
						onClick={resetFile}
					>
						<DeleteOutlineRounded />
					</Box>
				</Box>
			)}
		</Box>
	);
};
