import { FunctionComponent, useEffect, useState } from "react";

import { PaymentElement, AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { Alert, Typography } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import { Customer } from "@/pages/Private/pages/ProductLinks/schema/customers";
import { Subscription } from "@/pages/Private/pages/Subscriptions/schema/get-subscriptions";
import { useSaveCustomerAddressMutation } from "@/pages/Private/redux/subscription/subscription.api";
import { BuildSubscriptionResponse } from "@/pages/Private/pages/Subscriptions/schema/create-subscription";

interface PaymentProps {
	customer: Customer;
	subscription: Subscription;
	builtSubscription: BuildSubscriptionResponse;
}

export const Payment: FunctionComponent<PaymentProps> = ({
	builtSubscription,
	customer,
	subscription,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<string | null>();
	const [saveAddress, { isLoading: savingAddressIsLoading }] = useSaveCustomerAddressMutation();

	const [cPaymentMethod, setCPaymentmethod] = useState<string>();

	useEffect(() => {
		const listenForChanges = async () => {
			if (!elements) {
				return;
			}

			const paymentElement = await elements.getElement("payment");

			paymentElement?.on("change", function (event) {
				// eslint-disable-next-line no-console
				console.log(event.value.type);

				setCPaymentmethod(event.value.type);
			});
		};

		listenForChanges();
	}, [elements]);

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		setIsLoading(true);

		// eslint-disable-next-line no-console
		console.log(event);

		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const addressElement = await elements.getElement("address");

		if (addressElement) {
			const { value } = await addressElement.getValue();
			const { address, name } = value;

			await saveAddress({
				id: builtSubscription.id,
				body: {
					name,
					address,
					secret: builtSubscription.secret,
				},
			}).unwrap();

			if (subscription.installmentAmount === 0 && !subscription.invoice) {
				// const { error } = await stripe.confirmSetup({
				// 	//`Elements` instance that was used to create the Payment Element
				// 	elements,
				// 	confirmParams: {
				// 		return_url: `${window.location.origin}/setup-complete`,
				// 	},
				// });

				// if (error) {
				// 	setError(error.message);
				// } else {
				// 	// Your customer will be redirected to your `return_url`. For some payment
				// 	// methods like iDEAL, your customer will be redirected to an intermediate
				// 	// site first to authorize the payment, then redirected to the `return_url`.
				// }
				const returnUrl =
					cPaymentMethod === "customer_balance"
						? `${window.location.origin}/order-initialized`
						: `${window.location.origin}/order-complete`;
				const result = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					confirmParams: {
						return_url: returnUrl,
					},
				});

				if (result.error) {
					setError(result.error.message);
				} else {
					// Your customer will be redirected to your `return_url`. For some payment
					// methods like iDEAL, your customer will be redirected to an intermediate
					// site first to authorize the payment, then redirected to the `return_url`.
				}
			} else {
				const returnUrl =
					cPaymentMethod === "customer_balance"
						? `${window.location.origin}/order-initialized`
						: `${window.location.origin}/order-complete`;
				const result = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					confirmParams: {
						return_url: returnUrl,
					},
				});

				if (result.error) {
					setError(result.error.message);
				} else {
					// Your customer will be redirected to your `return_url`. For some payment
					// methods like iDEAL, your customer will be redirected to an intermediate
					// site first to authorize the payment, then redirected to the `return_url`.
				}
			}
		}

		setIsLoading(false);
	};

	return (
		<>
			<Typography variant="h3">
				Hallo {customer.firstName} {customer.lastName}
			</Typography>

			<form onSubmit={handleSubmit}>
				<Typography mb={2} mt={4} variant="h4">
					Ergänze deine Adressdaten:
				</Typography>
				<AddressElement
					options={{
						mode: "billing",
						defaultValues: {
							name: `${customer.firstName} ${customer.lastName}`,
							address: {
								country: "DE",
							},
						},
					}}
				/>
				<Typography mb={2} mt={4} variant="h4">
					Wähle eine Zahlungsmethode:
				</Typography>
				<PaymentElement />
				<LoadingButton
					loading={isLoading || savingAddressIsLoading}
					style={{ width: "100%", marginTop: "12px", fontWeight: "bold", fontSize: "16px" }}
					type="submit"
					variant="contained"
				>
					Zahlung abschließen
				</LoadingButton>
				{error ? (
					<Alert
						severity="error"
						sx={{ alignItems: "center", bgcolor: "error.light", fontSize: 14, mt: 2 }}
					>
						{error}
					</Alert>
				) : (
					""
				)}
			</form>
		</>
	);
};

export default Payment;
