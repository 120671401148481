export default {
	translation: {
		subscription: {
			title: "Subscriptions",
			name: "Subscription name (Internal)",
			asInvoice: "Generate as Invoice - not automatically charged",
			onetimeAmount: "Onetime amount",
			installmentAmount: "Installment amount",
			cycles: "Cycles",
			interval: "Interval",
			productName: "Product name",
			terms: "Payment terms",
			search: "Search",
			intervalPlaceholder: "Select payment interval",
			placeholder: "Select product",
			day: "daily",
			month: "monthly",
			week: "weekly",
			year: "yearly",
			employees: "Additional employee accounts",
			create: {
				button: "Create new subscription",
				title: "Create new subscription",
			},
			copied: "Copied to clipboard",
		},
		productLink: {
			title: "Product links",
			copied: "Copied to clipboard",
			customer: "Customer",
			productName: "Product name",
			uuid: "Unique identifier",
			status: "Status",
			placeholder: "Select product",
			create: {
				title: "Create new product link",
				button: "New product link",
			},
			delete: {
				title: "Delete product link",
			},
		},
		product: {
			name: "Name",
			title: "Products",
			onetimeAmount: "One time amount",
			monthlyAmount: "Monthly installment",
			cycles: "Billingcycles",
			actions: "Actions",
			search: "for product name",
			description: "Description",
			create: {
				title: "Create new Product",
				button: "New Product",
				description: "Description",
			},
			delete: {
				title: "Delete Product",
			},
		},
		account: {
			changePassword: "Change password",
			currentPassword: "Current password",
			disableTwoFactorAuth: "Disable 2FA",
			enableTwoFactorAuth: "Enable 2FA",
			enableTwoFactorAuthDescription: "Scan the QR code with your authenticator app",
			newPassword: "New Password",
			profileInformation: "Profile information",
			profileSecurity: "Profile security",
			repeatPassword: "Repeat new password",
			tabs: {
				general: "General",
				notifications: "Notifications",
			},
			title: "Account",
		},
		basics: {
			account: "Account",
			actions: "Actions",
			admin: "Admin",
			block: "Block",
			cancel: "Cancel",
			change: "Change",
			code: "Code",
			confirm: "Confirm",
			confirmPassword: "Confirm Password",
			createSomething: "Create {{something}}",
			delete: "Delete",
			description: "Description",
			edit: "Edit",
			editSomething: "Edit {{something}}",
			email: "E-mail address",
			enterSomething: "Enter {{something}}",
			firstName: "First name",
			home: "Home",
			label: "Label",
			lastName: "Last name",
			logout: "Logout",
			name: "Name",
			noData: "No data",
			password: "Password",
			permissions: "Permissions",
			resetPassword: "Reset password",
			role: "Role",
			save: "Save",
			saveChanges: "Save changes",
			search: "Search {{something}}...",
			select: "Select",
			selectSomething: "Select {{something}}",
			setPassword: "Set password",
			status: "Status",
			termsAndConditions: "<noLink>I have read the</noLink> <link1>Terms and Conditions</link1>",
			value: "Value",
			warning: "Attention",
		},
		errorPages: {
			buttonText: "Take Me Home",
			description:
				"You either tried some shady route or you came hee by mistake. Whichever it is, try using the navigation.",
			notFound: "404: The page you are looking for isn't here",
			serverError: "500: Internal Server Error",
			unauthorize: "401: Authorization required",
		},
		forgotPassword: {
			forgotPasswordCta: "Send reset link",
			submittedFeedbackMessage:
				"Check your inbox. We send an e-mail with instructions on how to reset your password!",
			title: "Forgot password",
		},
		inviteUser: {
			button: "Invite",
			modal: {
				cta: "Invite",
				emailPlaceholder: "Enter email",
				firstNamePlaceholder: "Enter first name",
				lastNamePlaceholder: "Enter last name",
				rolePlaceholder: "Select role",
				title: "Invite user",
			},
		},
		login: {
			emailPlaceholder: "Your e-mail address",
			forgotPasswordCta: "Forgot Password?",
			loginCta: "Login",
			passwordPlaceholder: "Your password",
			title: "Log in",
			invalidData: "email or password wrong",
		},
		modals: {
			addAvatar: {
				description: "<underline>Click to upload</underline> or drag and drop",
				fileSize: "Max file size is 3 MB",
				title: "Upload File",
			},
			deleteRole: {
				title: "Delete role",
			},
			deleteUser: {
				title: "Delete user",
			},
			editUser: {
				rolePlaceholder: "Select Roles",
				title: "Edit user",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Confirm",
			title: "Enter 2FA Code",
		},
		users: {
			title: "Users",
			search: "users",
		},
		userRoles: {
			title: "Roles",
			button: "User roles",
			modal: {
				create: {
					permissionPlaceholder: "Select Permissions",
					title: "Create role",
				},
				edit: {
					title: "Update role",
				},
			},
			placeholders: {
				codePlaceholder: "Enter",
				namePlaceholder: "Enter name",
			},
		},
	},
};
