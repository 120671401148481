import { z } from "zod";

import { IntervalEnum } from "@/enum/subscription-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

import { CreateProductSchema } from "../../Products/schema/create-product";

const { REQUIRED } = ERROR_TYPE;

export const SubscriptionSchema = z.object({
	id: z.number(),
	uuid: z.string(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	onetimeAmount: z.number().optional(),
	installmentAmount: z.number().optional(),
	invoice: z.boolean(),
	cycles: z.number().optional(),
	employees: z.number().optional(),
	interval: z.nativeEnum(IntervalEnum).optional(),
	product: CreateProductSchema,
});

export const SubscriptionsResponseSchema = z.object({
	data: z.array(SubscriptionSchema),
	meta: MetaDataSchema,
});

export type Subscription = z.infer<typeof SubscriptionSchema>;

export type SubscriptionsResponse = z.infer<typeof SubscriptionsResponseSchema>;
