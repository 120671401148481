import React from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";

import { worker as serverWorker } from "@/mocks/browser";
import { PARSED_ENV } from "@/constants/common";

import App from "./App";

Sentry.init({
	dsn: "https://215c6ad3ee14bc1a98151610efaa7d23@o4506931262849024.ingest.us.sentry.io/4506931265339392",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	environment: PARSED_ENV.REACT_APP_NODE_ENV || "production",
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/api\-zahlung\.thorstenkreutz\.com\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (PARSED_ENV.REACT_APP_NODE_ENV === "development") {
	serverWorker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);
