export default {
	translation: {
		errors: {
			requiredFields: "Nicht alle notwendigen Felder sind gefüllt.",
			expectedNumber: "Zahl erwartet / 0 eintragen, falls nicht benötigt.",
		},
		subscription: {
			title: "Abonnements",
			name: "Abonnements name (intern)",
			asInvoice: "auf Rechnung",
			onetimeAmount: "Einmalzahlung",
			installmentAmount: "Rate",
			cycles: "Zahlungszyklen",
			interval: "Intervaltyp",
			productName: "Produktname",
			terms: "Zahlungskonditionen",
			search: "Suche",
			intervalPlaceholder: "Wähle Interval",
			placeholder: "Wähle Produkt",
			day: "tägliche",
			month: "monatliche",
			week: "wöchentliche",
			year: "jährliche",
			employees: "Zusätzliche Mitarbeiteraccounts",
			create: {
				button: "Neues Abonnements",
				title: "Neues Abonnements",
			},
			delete: {
				title: "Abonnement löschen",
			},
			copied: "In Zwischenablage kopiert",
		},
		product: {
			name: "Name",
			title: "Produkte",
			onetimeAmount: "Anzahlung",
			monthlyAmount: "Monatliche Rate",
			cycles: "Zahlungszyklen",
			actions: "Aktionen",
			search: "nach Produktnamen",
			placeholder: "Wähle Produkt",
			description: "Beschreibung",
			create: {
				title: "Erstelle neues Produkt",
				button: "Neues Produkt",
				description: "Beschreibung",
			},
			delete: {
				title: "Lösche Produkt",
			},
		},
		account: {
			changePassword: "Change password",
			currentPassword: "Current password",
			disableTwoFactorAuth: "Disable 2FA",
			enableTwoFactorAuth: "Enable 2FA",
			enableTwoFactorAuthDescription: "Scan the QR code with your authenticator app",
			newPassword: "New Password",
			profileInformation: "Profile information",
			profileSecurity: "Profile security",
			repeatPassword: "Repeat new password",
			tabs: {
				general: "General DE",
				notifications: "Notifications DE",
			},
			title: "Account DE",
		},
		basics: {
			account: "Account",
			actions: "Actions",
			admin: "Admin",
			block: "blockieren",
			cancel: "Abbrechen",
			change: "Bearbeiten",
			code: "Code",
			confirm: "Bestätigen",
			confirmPassword: "Confirm Password",
			createSomething: "Create {{something}}",
			delete: "Löschen",
			description: "Beschreibung",
			dragAndDropImage: "Drag & Drop",
			edit: "Bearbeiten",
			editSomething: "Bearbeite {{something}}",
			email: "E-mail Adresse",
			enterSomething: "Enter {{something}}",
			firstName: "Vorname",
			home: "Home",
			label: "Label",
			lastName: "Nachname",
			logout: "Ausloggen",
			name: "Name",
			noData: "Keine Daten",
			password: "Passwort",
			permissions: "Berechtigungen",
			resetPassword: "Passwort zurücksetzen",
			role: "Rolle",
			save: "Speichern",
			saveChanges: "Speichere Änderungen",
			search: "Suche {{something}}...",
			select: "Wähle",
			selectSomething: "Wähle {{something}}",
			setPassword: "Setze Passwort",
			status: "Status",
			termsAndConditions: "<noLink>I have read the</noLink> <link1>Terms and Conditions</link1>",
			value: "Wert",
			warning: "Achtung",
		},
		errorPages: {
			buttonText: "Take Me Home",
			description:
				"You either tried some shady route or you came hee by mistake. Whichever it is, try using the navigation.",
			notFound: "404: The page you are looking for isn't here",
			serverError: "500: Internal Server Error",
			unauthorize: "401: Authorization required",
		},
		forgotPassword: {
			forgotPasswordCta: "Passwort zurücksetzen",
			submittedFeedbackMessage:
				"Prüfen Sie Ihren Posteingang. Wir senden Ihnen eine E-Mail mit Anweisungen, wie Sie Ihr Passwort zurücksetzen können!",
			title: "Passwort vergessen",
		},
		inviteUser: {
			button: "Invite",
			modal: {
				cta: "Invite",
				emailPlaceholder: "Enter email",
				firstNamePlaceholder: "Enter first name",
				lastNamePlaceholder: "Enter last name",
				rolePlaceholder: "Select role",
				title: "Invite user",
			},
		},
		login: {
			emailPlaceholder: "Deine E-Mail Adresse",
			forgotPasswordCta: "Passwort vergessen?",
			loginCta: "Anmelden",
			passwordPlaceholder: "Dein Passwort",
			title: "Anmelden",
			invalidData: "E-Mail Adresse oder Passwort falsch",
		},
		modals: {
			addAvatar: {
				description: "<underline>Click to upload</underline> or drag and drop",
				fileSize: "Max file size is 3 MB",
				title: "Upload File",
			},
			deleteRole: {
				title: "Delete role",
			},
			deleteUser: {
				title: "Delete user",
			},
			editUser: {
				rolePlaceholder: "Select Roles",
				title: "Edit user",
			},
		},
		twoFA: {
			code: "Code",
			cta: "Confirm",
			title: "Enter 2FA Code",
		},
		users: {
			title: "Benutzer",
			search: "Benutzer",
		},
		userRoles: {
			title: "Benutzerrollen",
			button: "Benutzerrollen",
			modal: {
				create: {
					permissionPlaceholder: "Select Permissions",
					title: "Neue Benutzerrolle",
				},
				edit: {
					title: "Update role",
				},
			},
			placeholders: {
				codePlaceholder: "Enter",
				namePlaceholder: "Enter name",
			},
		},
	},
};
