import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { zodResolver } from "@hookform/resolvers/zod";

import { useAppSelector } from "@/redux/hooks";
import { AuthError } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useAuthTwoFactorCodeMutation } from "../../redux/auth.api";
import { TwoFactorAuthSchema } from "./schema/twoFactorAuth";
import { authSelector } from "../../redux/auth.slice";

export const TwoFactorAuth: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`twoFA.${key}`);

	const { otpToken } = useAppSelector(authSelector);

	const [auth, { isLoading, error }] = useAuthTwoFactorCodeMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			code: "",
		},
		resolver: zodResolver(TwoFactorAuthSchema),
	});

	const formErrors = Object.values(errors).map((error) => error.message) as ERROR_TYPE[];

	const onSubmit = async ({ code }: { code: string }) => {
		try {
			await auth({ code, token: otpToken ?? "" }).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography mb={4} variant="h6">
				{ts("title")}
			</Typography>

			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				<TextField
					label={ts("code")}
					sx={{ width: "100%", mb: 2 }}
					variant="filled"
					{...register("code")}
				/>
				<LoadingButton
					loading={isLoading}
					size="large"
					sx={{ width: "100%" }}
					type="submit"
					variant="contained"
				>
					{ts("cta")}
				</LoadingButton>
			</Box>

			{getAllErrors(error, formErrors).length ? (
				<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
			) : null}
		</Box>
	);
};
