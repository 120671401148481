import { FunctionComponent } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "@/redux/hooks";
import { PUBLIC_PAGES_AFTER_LOGIN, ROUTE_CONFIG } from "@/routes/config";
import { AuthLayout, ErrorPage } from "@/components";
import { ErrorPageType } from "@/enum";

import { PlinkLayout } from "@/components/Layouts/PlinkLayout";

import { Login } from "./pages/Login/Login";
import { authSelector } from "./redux/auth.slice";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { SetPassword } from "./pages/SetPassword/SetPassword";
import { TwoFactorAuth } from "./pages/TwoFactorAuth/TwoFactorAuth";
import { Plink } from "./pages/Plink/Plink";
import { OrderComplete } from "./pages/OrderComplete/OrderComplete";
import { OrderFailed } from "./pages/OrderFailed/OrderFailed";
import { SetupComplete } from "./pages/SetupComplete/SetupComplete";
import { ChangePassword } from "./pages/ChangePassword/ChangePassword";
import { OrderInitialized } from "./pages/OrderInitialized/OrderInitialized";

const { USERS, TFA } = ROUTE_CONFIG;

export const Public: FunctionComponent = () => {
	const { user, otpToken } = useAppSelector(authSelector);
	const { pathname } = useLocation();

	const publicPages = PUBLIC_PAGES_AFTER_LOGIN.find((pp: string) => pathname.includes(pp));

	if (user && !otpToken && !publicPages) {
		return <Navigate to={USERS} />;
	}

	if (user && otpToken && pathname !== TFA) {
		return <Navigate to={TFA} />;
	}

	return (
		<Routes>
			<Route element={<Navigate to="/login" />} path="/" />
			<Route element={<AuthLayout />}>
				<Route element={<Login />} path="login" />
				<Route element={<TwoFactorAuth />} path="2fa" />
				<Route element={<SetPassword />} path="/set-password" />
				<Route element={<ChangePassword />} path="/change-password" />
				<Route element={<ForgotPassword />} path="/forgot-password" />
			</Route>
			<Route element={<PlinkLayout />}>
				<Route element={<Plink />} path="/plink/:uuid" />
				<Route element={<OrderComplete />} path="/order-complete" />
				<Route element={<OrderInitialized />} path="/order-initialized" />
				<Route element={<OrderFailed />} path="/order-failed" />
				<Route element={<SetupComplete />} path="/setup-complete" />
			</Route>
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
		</Routes>
	);
};
