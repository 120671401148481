import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/redux";
import { AuthState } from "@/types/auth";

import { accountApi } from "@/pages/Private/redux/account/account.api";

import { authApi } from "./auth.api";

const initialState: AuthState = {
	otpToken: null,
	token: localStorage.getItem("token") || null,
	user: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		resetAuth: (state) => {
			localStorage.clear();
			state.user = null;
			state.token = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
				const { otpToken, accessToken, user } = payload;

				if (accessToken) {
					localStorage.setItem("token", accessToken);
					state.token = accessToken;
				}

				if (otpToken) {
					state.otpToken = otpToken;
				}

				state.user = user;
			})
			.addMatcher(authApi.endpoints.fetchMe.matchFulfilled, (state, { payload }) => {
				state.user = payload;
			})
			.addMatcher(accountApi.endpoints.updateMe.matchFulfilled, (state, { payload }) => {
				state.user = payload;
			})
			.addMatcher(authApi.endpoints.authTwoFactorCode.matchFulfilled, (state, { payload }) => {
				const { accessToken, user } = payload;

				if (accessToken) {
					localStorage.setItem("token", accessToken);
					state.token = accessToken;
					state.otpToken = null;
				}

				state.user = user;
			})
			.addMatcher(accountApi.endpoints.verifyTwoFactor.matchFulfilled, (state, { payload }) => {
				state.user = payload;
			})
			.addMatcher(accountApi.endpoints.disableTwoFactor.matchFulfilled, (state, { payload }) => {
				state.user = payload;
			});
	},
});

export const { resetAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const authSelector = (state: RootState) => state[authSlice.name];
