import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/system";
import {
	Button,
	Card,
	CardContent,
	Unstable_Grid2 as Grid,
	TextField,
	Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useUpdateMeMutation } from "@/pages/Private/redux/account/account.api";
import { Avatar, FileUploader } from "@/components";

import {
	UpdateFirstName,
	UpdateFirstNameSchema,
	UpdateLastNameSchema,
	UpdateLastName,
} from "../schema/account";
import { ChangePasswordModal } from "./ChangePasswordModal";

type ProfileInformationProps = {
	firstName: string;
	lastName: string;
};

enum FieldType {
	FIRSTNAME = "firstName",
	LASTNAME = "lastName",
}

type SubmitValues = {
	[FieldType.FIRSTNAME]?: string;
	[FieldType.LASTNAME]?: string;
};

type FieldsConfig = {
	firstName: boolean;
	lastName: boolean;
	canSubmitField: FieldType | null;
};

export const ProfileInformation: FunctionComponent<ProfileInformationProps> = ({
	firstName,
	lastName,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);
	const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
	const [avatarSrc, setAvatarSrc] = useState<string>("");
	const [canEdit, setCanEdit] = useState<FieldsConfig>({
		firstName: false,
		lastName: false,
		canSubmitField: null,
	});
	const [updateMe, { isLoading }] = useUpdateMeMutation();

	const {
		register: firstNameRegister,
		handleSubmit: firstNameHandleSubmit,
		formState: { errors: firstNameErrors, defaultValues: firstNameDefaultValues },
		setFocus: firstNameSetFocus,
		reset: firstNameReset,
	} = useForm<UpdateFirstName>({
		defaultValues: {
			firstName,
		},
		resolver: zodResolver(UpdateFirstNameSchema),
	});

	const {
		register: lastNameRegister,
		handleSubmit: lastNameHandleSubmit,
		formState: { errors: lastNameErrors, defaultValues: lastNameDefaultValues },
		setFocus: lastNameSetFocus,
		reset: lastNameReset,
	} = useForm<UpdateLastName>({
		defaultValues: {
			lastName,
		},
		resolver: zodResolver(UpdateLastNameSchema),
	});

	const onSubmit = async (values: SubmitValues) => {
		const submittedFieldName =
			canEdit.canSubmitField === FieldType.FIRSTNAME &&
			firstNameDefaultValues?.firstName !== (values as UpdateFirstName).firstName
				? FieldType.FIRSTNAME
				: canEdit.canSubmitField === FieldType.LASTNAME &&
				  lastNameDefaultValues?.lastName !== (values as UpdateLastName).lastName
				? FieldType.LASTNAME
				: null;

		if (submittedFieldName) {
			try {
				await updateMe({
					[submittedFieldName]: values[submittedFieldName],
				}).unwrap();

				setCanEdit({
					...canEdit,
					[submittedFieldName]: false,
				});

				if (submittedFieldName === FieldType.FIRSTNAME) {
					firstNameReset({ [submittedFieldName]: values[submittedFieldName] });
				} else {
					lastNameReset({ [submittedFieldName]: values[submittedFieldName] });
				}
			} catch (err) {
				console.error(err);
			}
		}
	};

	const onClickHandler = (field: FieldType) => {
		if (!canEdit[field]) {
			field === FieldType.FIRSTNAME ? firstNameSetFocus(field) : lastNameSetFocus(field);
		}

		setCanEdit({
			...canEdit,
			[field]: true,
			canSubmitField:
				field === FieldType.FIRSTNAME && canEdit.firstName
					? FieldType.FIRSTNAME
					: field === FieldType.LASTNAME && canEdit.lastName
					? FieldType.LASTNAME
					: null,
		});
	};

	return (
		<>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid md={4} xs={12}>
							<Typography variant="h6">{ts("profileInformation")}</Typography>
						</Grid>

						<Grid md={8} xs={12}>
							<Stack spacing={3}>
								<Box>
									<Stack direction="row" spacing={2}>
										<Box onClick={() => setShowAvatarModal(true)}>
											<Avatar imageSrc={avatarSrc} />
										</Box>

										<Button onClick={() => setShowAvatarModal(true)}>{t("basics.change")}</Button>

										<FileUploader
											handleClose={() => setShowAvatarModal(false)}
											handleFile={(file) => setAvatarSrc(URL.createObjectURL(file))}
											isOpened={showAvatarModal}
										/>
									</Stack>
								</Box>

								<Box component="form" onSubmit={firstNameHandleSubmit(onSubmit)}>
									<Stack alignItems="center" direction="row" spacing={2}>
										<TextField
											fullWidth
											error={!!firstNameErrors.firstName?.message}
											inputProps={{ readOnly: !canEdit.firstName }}
											label={t("basics.firstName")}
											variant="filled"
											{...firstNameRegister("firstName")}
										/>

										<LoadingButton
											disabled={!!firstNameErrors.firstName?.message}
											loading={canEdit.canSubmitField === FieldType.FIRSTNAME && isLoading}
											type="submit"
											onClick={() => onClickHandler(FieldType.FIRSTNAME)}
										>
											{canEdit.firstName ? t("basics.save") : t("basics.edit")}
										</LoadingButton>
									</Stack>
								</Box>

								<Box component="form" onSubmit={lastNameHandleSubmit(onSubmit)}>
									<Stack alignItems="center" direction="row" spacing={2}>
										<TextField
											fullWidth
											error={!!lastNameErrors.lastName?.message}
											inputProps={{ readOnly: !canEdit.lastName }}
											label={t("basics.lastName")}
											variant="filled"
											{...lastNameRegister("lastName")}
										/>

										<LoadingButton
											disabled={!!lastNameErrors.lastName?.message}
											loading={canEdit.canSubmitField === FieldType.LASTNAME && isLoading}
											type="submit"
											onClick={() => onClickHandler(FieldType.LASTNAME)}
										>
											{canEdit.lastName ? t("basics.save") : t("basics.edit")}
										</LoadingButton>
									</Stack>
								</Box>

								<Stack alignItems="center" direction="row" spacing={2}>
									<TextField
										fullWidth
										defaultValue="password"
										inputProps={{ readOnly: true }}
										label={t("basics.password")}
										type="password"
										variant="filled"
									/>

									<LoadingButton onClick={() => setShowPasswordModal(true)}>
										{t("basics.edit")}
									</LoadingButton>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{showPasswordModal && (
				<ChangePasswordModal
					handleClose={() => setShowPasswordModal(false)}
					isVisible={showPasswordModal}
				/>
			)}
		</>
	);
};
