import { ReactNode, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@mui/material";

export interface TableColumn<DataType> {
	label: string;
	minWidth?: number;
	format: (row: DataType) => ReactNode;
	align?: "right" | "left" | "center";
}

interface TableComponentProps<DataType extends { id: string | number } = { id: string | number }> {
	columns: TableColumn<DataType>[];
	data: DataType[];
	isLoading?: boolean;
	page: number;
	limit: number;
	itemCount: number;
	handleChangePage: (event: unknown, page: number) => void;
	handleChangeLimit: (event: ChangeEvent<HTMLInputElement>) => void;
	noDataText?: string;
	allowNavigation?: boolean;
	hidePagination?: boolean;
}

export function TableComponent<DataType extends { id: string | number }>({
	columns,
	data,
	page,
	limit,
	itemCount,
	handleChangePage,
	handleChangeLimit,
	noDataText,
	allowNavigation,
	hidePagination,
}: TableComponentProps<DataType>) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<Table stickyHeader aria-label="sticky table">
				<TableHead sx={{ bgcolor: "white" }}>
					<TableRow>
						{columns.map((column, index) => (
							<TableCell
								key={index}
								align={column.align}
								style={{ minWidth: column.minWidth }}
								variant="head"
							>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{data && data.length > 0 ? (
						data.map((row) => (
							<TableRow
								key={row.id}
								sx={{ cursor: allowNavigation ? "pointer" : "" }}
								tabIndex={-1}
								onClick={() => (allowNavigation ? navigate(`${row.id}`) : null)}
							>
								{columns.map((column, index) => (
									<TableCell key={index} align={column.align}>
										{column.format(row)}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={columns.length}>{noDataText ?? t("basics.noData")}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			{data && data.length > 0 && !hidePagination && (
				<TablePagination
					component="div"
					count={itemCount ?? 0}
					page={page}
					rowsPerPage={limit}
					rowsPerPageOptions={[10, 25, 50, 100]}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeLimit}
				/>
			)}
		</>
	);
}
