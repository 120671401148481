import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Card,
	InputAdornment,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { faTrashCan, faMagnifyingGlass, faPenToSquare } from "@fortawesome/pro-light-svg-icons";

import {
	useGetProductQuery,
	useDeleteProductMutation,
} from "@/pages/Private/redux/product/product.api";
import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import { PermissionRoles } from "@/enum";

import { Product } from "../schema/get-products";

import { EditProductModal } from "./EditProductModal";

export const ProductsTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`product.${key}`), [t]);
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

	const { page, setPage, limit, setLimit, searchValue, setSearchValue } = useSearch();
	const { data: products } = useGetProductQuery({
		page: page + 1,
		limit,
		searchValue,
	});
	const [deleteProduct, { isLoading }] = useDeleteProductMutation();

	const columns: TableColumn<Product>[] = useMemo(
		() => [
			{
				label: ts("name"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.name}
						</Typography>
					</Stack>
				),
			},
			{
				label: ts("description"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.description?.substring(0, 50)}...
						</Typography>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									cursor: "pointer",
									color: "primary.main",
								},
							}}
							onClick={() => handleEditProduct(row)}
						>
							<Icon icon={faPenToSquare} size="xl" />
						</Box>
						<PermissionCheck requiredPermissions={[PermissionRoles.DELETE_PRODUCT]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(0);
	};

	const handleEditProduct = (row: Product) => {
		setShowEditModal(true);
		setSelectedProduct(row);
	};

	const handleDeleteModal = (row: Product) => {
		setShowDeleteModal(true);
		setSelectedProduct(row);
	};

	const handleDeleteProduct = async () => {
		try {
			await deleteProduct(selectedProduct?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	const handleCloseEditModal = () => {
		setShowEditModal(false);
		setSelectedProduct(null);
	};

	// eslint-disable-next-line no-console
	console.log(selectedProduct);

	return !products ? (
		<LoadingOverlay />
	) : (
		<Card>
			<Stack direction="row" gap={2} px={3} py={2}>
				<OutlinedInput
					fullWidth
					placeholder={t("basics.search", { something: ts("search") })}
					startAdornment={
						<InputAdornment position="start">
							<Icon icon={faMagnifyingGlass} />
						</InputAdornment>
					}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</Stack>
			<TableComponent
				columns={columns}
				data={products?.data || []}
				handleChangeLimit={handleChangeRowsPerPage}
				handleChangePage={(_: unknown, page: number) => setPage(page)}
				itemCount={products?.meta.itemCount ?? 0}
				limit={limit}
				page={page}
			/>

			{showEditModal && selectedProduct && (
				<EditProductModal
					isOpen={showEditModal}
					product={selectedProduct}
					setIsOpen={handleCloseEditModal}
				/>
			)}

			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteProduct}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					{selectedProduct && selectedProduct.subscription.length > 0 ? (
						<TextField
							fullWidth
							multiline
							defaultValue={`Dieses Produkt hat ${selectedProduct?.subscription?.length} zugewiesene Abonnements. Diese sind nach dem Löschen dieses Produkts nicht mehr verfügbar.`}
							inputProps={{ readOnly: true }}
							label={t("basics.warning")}
						></TextField>
					) : (
						<></>
					)}
					<TextField
						fullWidth
						defaultValue={selectedProduct?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
		</Card>
	);
};
