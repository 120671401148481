import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";

import { ProfileInformation } from "./components/ProfileInformation";
import { ProfileSecurity } from "./components/ProfileSecurity";

enum Tabs {
	GENERAL = "GENERAL",
	NOTIFICATIONS = "NOTIFICATIONS",
}

export const Account: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`account.${key}`);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.GENERAL);

	const { user } = useAppSelector(authSelector);

	const tabs = [
		{ label: ts("tabs.general"), value: Tabs.GENERAL },
		{ label: ts("tabs.notifications"), value: Tabs.NOTIFICATIONS },
	];

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	return (
		<>
			<Typography mb={4} variant="h4">
				{ts("title")}
			</Typography>

			<TabContext value={currentTab}>
				<TabList aria-label="Account tabs" sx={{ mb: 4 }} onChange={handleTabsChange}>
					{tabs.map((tab, index) => (
						<Tab key={index} label={tab.label} value={tab.value} />
					))}
				</TabList>

				<TabPanel sx={{ p: 0 }} value={Tabs.GENERAL}>
					<Stack spacing={4}>
						<ProfileInformation firstName={user?.firstName ?? ""} lastName={user?.lastName ?? ""} />
						<ProfileSecurity is2FAEnabled={user?.is2FAEnabled || false} />
					</Stack>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={Tabs.NOTIFICATIONS}>
					{ts("tabs.notifications")}
				</TabPanel>
			</TabContext>
		</>
	);
};
