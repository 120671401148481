import { FunctionComponent, useEffect, useState } from "react";

import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import { faCheckCircle, faClock } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import { usePaySubscriptionMutation } from "@/pages/Private/redux/subscription/subscription.api";
import { PaySubscriptionResponse } from "@/pages/Private/pages/Subscriptions/schema/create-subscription";
import { LoadingOverlay } from "@/components";
import { ROUTE_CONFIG } from "@/routes/config";

export const SetupComplete: FunctionComponent = () => {
	const theme = useTheme();
	const [params] = useSearchParams();
	const [paidSubscription, setPaidSubscription] = useState<PaySubscriptionResponse>();
	const setupIntentClientSecret = params.get("setup_intent_client_secret");
	const navigate = useNavigate();
	const [paySubscription, { isLoading }] = usePaySubscriptionMutation();

	useEffect(() => {
		const initiatePayment = async (setupIntentClientSecret: string) => {
			try {
				const paidSubscriptionResponse = await paySubscription({
					secret: setupIntentClientSecret,
				}).unwrap();

				setPaidSubscription(paidSubscriptionResponse);
			} catch (e) {
				navigate(ROUTE_CONFIG.ORDER_FAILED);
			}
		};

		if (setupIntentClientSecret) {
			initiatePayment(setupIntentClientSecret);
		}
	}, [navigate, paySubscription, setupIntentClientSecret]);

	const onDownload = (url: string) => {
		const link = document.createElement("a");
		const fileNameArr = url.split("/");

		link.download = fileNameArr[-1];
		link.href = url;
		link.click();
	};

	return (
		<Container>
			{paidSubscription ? (
				<Stack alignItems={"center"} direction="column" justifyContent={"center"}>
					<Box mb={6} mt={6}>
						<FontAwesomeIcon color={theme.palette["success"].dark} icon={faCheckCircle} size="9x" />
					</Box>
					<Box justifyContent={"center"} textAlign={"center"}>
						{paidSubscription.status === "already_paid" ? (
							<Typography variant="h1">Bestellung bereits aufgenommen......</Typography>
						) : (
							<Typography variant="h1">Bestellung aufgenommen...</Typography>
						)}

						<Typography variant="body1">
							Du hast die Rechnung ebenfalls automatisch per E-Mail erhalten.
						</Typography>
						{paidSubscription.pdf ? (
							<LoadingButton
								loading={isLoading}
								style={{
									width: "100%",
									marginTop: "12px",
									fontWeight: "bold",
									fontSize: "16px",
								}}
								variant="text"
								onClick={() => onDownload(paidSubscription.pdf)}
							>
								Rechnung herunterladen
							</LoadingButton>
						) : (
							""
						)}
					</Box>
				</Stack>
			) : (
				<Stack alignItems={"center"} direction="column" justifyContent={"center"}>
					<Box mb={6} mt={6}>
						<FontAwesomeIcon color={theme.palette["warning"].main} icon={faClock} size="9x" />
					</Box>
					<Box justifyContent={"center"} textAlign={"center"}>
						<Typography variant="h1">Zahlung wird initiiert</Typography>
						<Typography variant="body1">Bitte warten...</Typography>
					</Box>
					<LoadingOverlay />
				</Stack>
			)}
		</Container>
	);
};
