import { z } from "zod";

import { ERROR_TYPE } from "@/utils";

import { IntervalEnum } from "@/enum/subscription-enum";

import { CustomerSchema } from "../../ProductLinks/schema/customers";
import { SubscriptionSchema } from "./get-subscriptions";

const { REQUIRED } = ERROR_TYPE;

export const CreateSubscriptionSchema = z
	.object({
		name: z.string().min(4, { message: REQUIRED }).trim(),
		productId: z.number().min(1, { message: REQUIRED }),
		onetimeAmount: z.number(),
		installmentAmount: z.number().optional(),
		cycles: z.number().optional(),
		employees: z.number().optional(),
		invoice: z.boolean(),
		interval: z.nativeEnum(IntervalEnum).optional(),
	})
	.superRefine(({ installmentAmount, cycles, interval }, refinementContext) => {
		if (installmentAmount !== undefined && cycles === undefined) {
			return refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: "cycles missing",
				path: ["cycles"],
			});
		}

		if (installmentAmount !== undefined && interval === undefined) {
			return refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: "interval missing",
				path: ["interval"],
			});
		}
	});

export const BuildSubscriptionSchema = z.object({
	subscriptionId: z.number(),
	customer: CustomerSchema,
});

export const BuildSubscriptionResponseSchema = z.object({
	id: z.number(),
	secret: z.string(),
	pdf: z.string(),
	url: z.string(),
});

export const AddressSchema = z.object({
	line1: z.string(),
	line2: z.string().nullable(),
	city: z.string(),
	state: z.string(),
	postal_code: z.string(),
	country: z.string(),
});

export const SaveCustomerAddressRequestSchema = z.object({
	id: z.number(),
	body: z.object({
		name: z.string(),
		secret: z.string(),
		address: AddressSchema,
	}),
});

export const SaveCustomerAddressResponseSchema = z.object({
	status: z.boolean(),
});

export const PaySubscriptionRequestSchema = z.object({
	secret: z.string(),
});

export const PaySubscriptionResponseSchema = z.object({
	pdf: z.string(),
	url: z.string(),
	status: z.string().optional(),
});

export type SubscriptionResponse = z.infer<typeof SubscriptionSchema>;
export type CreateSubscriptionRequest = z.infer<typeof CreateSubscriptionSchema>;
export type SaveCustomerAddressRequest = z.infer<typeof SaveCustomerAddressRequestSchema>;
export type SaveCustomerAddressResponse = z.infer<typeof SaveCustomerAddressResponseSchema>;

export type BuildSubscriptionRequest = z.infer<typeof BuildSubscriptionSchema>;
export type PaySubscriptionRequest = z.infer<typeof PaySubscriptionRequestSchema>;
export type PaySubscriptionResponse = z.infer<typeof PaySubscriptionResponseSchema>;

export type BuildSubscriptionResponse = z.infer<typeof BuildSubscriptionResponseSchema>;

export interface UpdateSubscriptionBody {
	id: number;
	body: CreateSubscriptionRequest;
}
