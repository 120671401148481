import { FunctionComponent } from "react";
import { Box } from "@mui/material";

export const TkDivider: FunctionComponent = () => (
	<Box
		style={{
			background: "linear-gradient(225deg, #aa8267 0%, #f1d3ac)",
			height: "1px",
			marginTop: "12px",
			marginBottom: "12px",
		}}
	/>
);
