import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTE_CONFIG } from "@/routes/config";
import { useAppSelector } from "@/redux/hooks";
import { ErrorPage } from "@/components";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { hasPermission, isAdmin } from "@/utils";
import { ErrorPageType, PermissionRoles } from "@/enum";

import { UserRoles } from "../pages/UserRoles/UserRoles";
import { Users } from "../pages/Users/Users";
import { Products } from "../pages/Products/Products";

const { LOGIN, SUBSCRIPTIONS, PRODUCTS } = ROUTE_CONFIG;

export const Admin: FunctionComponent = () => {
	const { user } = useAppSelector(authSelector);

	if (!user) {
		return <Navigate to={LOGIN} />;
	}

	if (!isAdmin(user) && hasPermission(user, [PermissionRoles.READ_SUBSCRIPTION])) {
		return <Navigate to={SUBSCRIPTIONS} />; // Home page,
	}

	if (!isAdmin(user) && hasPermission(user, [PermissionRoles.READ_PRODUCTS])) {
		return <Navigate to={PRODUCTS} />; // Home page,
	}

	return (
		<Routes>
			<Route element={<Users />} path="/" />
			<Route element={<UserRoles />} path="/user-roles" />
			<Route element={<Products />} path="/products" />
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
		</Routes>
	);
};
