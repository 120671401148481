import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Container, Grid } from "@mui/material";

import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";

import { LoadingOverlay } from "@/components";

import { PARSED_ENV } from "@/constants";

import {
	useBuildSubscriptionMutation,
	useGetSubscriptionQuery,
} from "@/pages/Private/redux/subscription/subscription.api";

import { Customer } from "@/pages/Private/pages/ProductLinks/schema/customers";

import {
	BuildSubscriptionRequest,
	BuildSubscriptionResponse,
} from "@/pages/Private/pages/Subscriptions/schema/create-subscription";

import Payment from "./components/Payment";
import { ProductInfo } from "./components/ProductInfo";

import { CustomerForm } from "./components/CustomerForm";

export const Plink: FunctionComponent = () => {
	const { uuid = "0" } = useParams();
	const [customerFromParam] = useSearchParams();
	const email = customerFromParam.get("email");
	const firstName = customerFromParam.get("firstName");
	const lastName = customerFromParam.get("lastName");

	const [secret, setSecret] = useState<string>();
	const [builtSubscription, setBuiltSubscription] = useState<BuildSubscriptionResponse>();
	const [customer, setCustomer] = useState<Customer>();
	const [buildSubscription, { isLoading }] = useBuildSubscriptionMutation();

	const { data } = useGetSubscriptionQuery(uuid);

	const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

	useEffect(() => {
		if (builtSubscription?.secret && PARSED_ENV.REACT_APP_STRIPE_PUBLIC_KEY) {
			loadStripe(PARSED_ENV.REACT_APP_STRIPE_PUBLIC_KEY).then((secret) => {
				setStripePromise(secret);
			});
		}
	}, [builtSubscription]);

	useEffect(() => {
		const buildNewSubscription = async (params: BuildSubscriptionRequest) => {
			const currentSubscription = await buildSubscription(params).unwrap();

			setBuiltSubscription(currentSubscription);
			setSecret(currentSubscription?.secret);
		};

		if (data && customer) {
			buildNewSubscription({ customer, subscriptionId: data.id });
		}
	}, [buildSubscription, customer, data]);

	// const onDownload = (url: string) => {
	// 	const link = document.createElement("a");
	// 	const fileNameArr = url.split("/");

	// 	link.download = fileNameArr[-1];
	// 	link.href = url;
	// 	link.click();
	// };

	return (
		<Container>
			{!data ? (
				<LoadingOverlay />
			) : (
				<Grid container spacing={0}>
					{data ? <ProductInfo subscription={data} /> : ""}
					<Grid xs={1}></Grid>
					<Grid xs={5}>
						{isLoading ? (
							<LoadingOverlay />
						) : (
							<>
								{!secret && setCustomer ? (
									<CustomerForm
										presetCustomer={{ email, firstName, lastName } as Customer}
										setCustomer={setCustomer}
									/>
								) : (
									""
								)}
								{builtSubscription && stripePromise && customer ? (
									<Elements
										options={{
											clientSecret: builtSubscription.secret,
										}}
										stripe={stripePromise}
									>
										<Payment
											builtSubscription={builtSubscription}
											customer={customer}
											subscription={data}
										/>
									</Elements>
								) : (
									""
								)}
							</>
						)}
					</Grid>
				</Grid>
			)}
		</Container>
	);
};
