import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { Stack } from "@mui/system";

import ReactQuill from "react-quill";

import { useUploadImage } from "@/pages/Private/helpers/useUploadImage";
import { Modal } from "@/components";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { useUpdateProductMutation } from "@/pages/Private/redux/product/product.api";

import { ImageUploader } from "@/components/ImageUploader/ImageUploader";
import "react-quill/dist/quill.snow.css";

import {
	CreateProductRequest,
	CreateProductSchema,
	ProductResponse,
} from "../schema/create-product";
import { Product } from "../schema/get-products";

interface createProductModalProps {
	product: Product;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditProductModal: FunctionComponent<createProductModalProps> = ({
	product,
	isOpen,
	setIsOpen,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`product.${key}`);

	const [updateProduct, { isLoading, error }] = useUpdateProductMutation();
	const { uploadImage } = useUploadImage();
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CreateProductRequest>({
		defaultValues: product,
		resolver: zodResolver(CreateProductSchema),
	});

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	const onSubmit = async (values: ProductResponse) => {
		try {
			const { image } = values;

			if (image && image !== "") {
				const imageUrl = await uploadImage(image);

				values.image = imageUrl;
			} else {
				values.image = null;
			}

			const params = { id: product.id, body: { ...values } };

			await updateProduct(params).unwrap();
			setIsOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	// eslint-disable-next-line no-console
	console.log(error, formErrors);

	return (
		<Modal
			handleClose={() => setIsOpen(false)}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={isOpen}
			submitButtonText={t("basics.confirm")}
			title={ts("create.title")}
		>
			<Stack spacing={2}>
				<TextField
					error={!!errors.name?.message}
					label={ts("name")}
					placeholder={ts("name")}
					{...register("name")}
				/>

				<ReactQuill
					theme="snow"
					value={getValues("description")}
					onChange={(e) => {
						setValue("description", e);
					}}
				/>

				<Stack direction="column" spacing={2}>
					{getValues("image") ? (
						<ImageUploader
							currentImgUrl={getValues("image") as string}
							setCurrentImgUrl={(img) => setValue("image", img, { shouldValidate: true })}
						/>
					) : (
						<ImageUploader
							setCurrentImgUrl={(img) => setValue("image", img, { shouldValidate: true })}
						/>
					)}
				</Stack>

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</Stack>
		</Modal>
	);
};
