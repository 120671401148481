import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./translations/de";
import en from "./translations/en";

i18n.use(initReactI18next).init({
	returnNull: false,
	fallbackLng: "de",
	lng: "de",
	resources: {
		de,
		en,
	},
});

export default i18n;
