import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTE_CONFIG } from "@/routes/config";
import { useAppSelector } from "@/redux/hooks";
import { ErrorPage, MainLayout } from "@/components";
import { ErrorPageType } from "@/enum";

import { authSelector } from "../Public/redux/auth.slice";
import { Account } from "./pages/Account/Account";
import { Admin } from "./routes/Admin";
import { ProductRoutes } from "./routes/ProductRoutes";
import { Subscriptions } from "./pages/Subscriptions/Subscriptions";

const { LOGIN } = ROUTE_CONFIG;

export const Private: FunctionComponent = () => {
	const { user } = useAppSelector(authSelector);

	if (!user) {
		return <Navigate to={LOGIN} />;
	}

	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route element={<Account />} path="/account" />
				<Route element={<Admin />} path="/users/*" />
				<Route element={<ProductRoutes />} path="/products/*" />
				<Route element={<Subscriptions />} path="/subscriptions/*" />
			</Route>
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
		</Routes>
	);
};
