import { z } from "zod";

import { ERROR_TYPE } from "@/utils";

import { ProductSchema } from "./get-products";

const { REQUIRED } = ERROR_TYPE;

export const CreateProductSchema = z.object({
	name: z.string().min(4, { message: REQUIRED }).trim(),
	description: z.string(),
	image: z.string().optional().nullable(),
});

export const CreateProductResponseSchema = ProductSchema;

export type ProductResponse = z.infer<typeof ProductSchema>;
export type CreateProductRequest = z.infer<typeof CreateProductResponseSchema>;

export interface UpdateProductBody {
	id: number;
	body: CreateProductRequest;
}
