import { FunctionComponent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { TokenPurpose } from "@/enum";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { AuthError } from "@/components";

import { ROUTE_CONFIG } from "@/routes/config";

import { useSetPasswordMutation } from "../../redux/auth.api";
import { SetPasswordSchema, SetPasswordType } from "./schema/set-password";

export const ChangePassword: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`basics.${key}`);

	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [setPassword, { isLoading, error }] = useSetPasswordMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SetPasswordType>({
		defaultValues: {
			password: "",
			confirmPassword: "",
			termsAndConditions: false,
		},
		resolver: zodResolver(SetPasswordSchema),
	});

	const formErrors = Object.values(errors).map((error) => error.message) as ERROR_TYPE[];

	const onSubmit = async (values: SetPasswordType) => {
		try {
			await setPassword({
				token: searchParams.get("token") || "",
				purpose: TokenPurpose.FORGOTTEN_PASSWORD,
				password: values.password,
				confirmPassword: values.confirmPassword,
			}).unwrap();
			navigate(ROUTE_CONFIG.LOGIN);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Box>
			<Typography mb={4} variant="h6">
				{ts("setPassword")}
			</Typography>

			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				<TextField
					label={ts("password")}
					sx={{ width: "100%", mb: 2 }}
					variant="filled"
					{...register("password")}
					type="password"
				/>

				<TextField
					label={ts("confirmPassword")}
					sx={{ width: "100%" }}
					variant="filled"
					{...register("confirmPassword")}
					type="password"
				/>

				<Box sx={{ mt: 3.5, display: "flex", alignItems: "center" }}>
					<Checkbox {...register("termsAndConditions")} />

					<Trans
						components={{
							noLink: <Typography color="text.secondary" component="p" ml={0.5} variant="body2" />,
							link1: (
								<Typography
									color="primary"
									component="a"
									href="https://www.google.bg"
									rel="noreferrer"
									sx={{ ml: 0.5, textDecoration: "none" }}
									target="_blank"
									variant="subtitle2"
								/>
							),
						}}
						i18nKey="basics.termsAndConditions"
					/>
				</Box>

				<LoadingButton
					loading={isLoading}
					size="large"
					sx={{ width: "100%", mt: 3.5 }}
					type="submit"
					variant="contained"
				>
					{ts("confirm")}
				</LoadingButton>
			</Box>

			{getAllErrors(error, formErrors).length ? (
				<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
			) : null}
		</Box>
	);
};
