import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearch = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const paramPageValue = searchParams.get("page") || 0;

	const [searchValue, setSearchValue] = useState<string>("");
	const [query, setQuery] = useState<string>("");
	const [page, setPage] = useState<number>(Number(paramPageValue));
	const [limit, setLimit] = useState<number>(10);

	const handleSetPage = (page: number) => {
		setPage(page);
		setSearchParams({ ...searchParams, ...{ page: String(page) } }, { replace: true });
	};

	const searchTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (searchTimer.current) {
			clearTimeout(searchTimer.current);
		}

		searchTimer.current = setTimeout(() => {
			setQuery(searchValue);
		}, 500);
	}, [searchValue]);

	return { searchValue, setSearchValue, query, page, setPage: handleSetPage, limit, setLimit };
};
