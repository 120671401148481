// import i18n from "../i18n";
import UkFlag from "@/assets/images/flags/flag-uk.svg";
import DeFlag from "@/assets/images/flags/flag-de.svg";

export const languages = [
	{
		name: "English",
		shortName: "En",
		code: "en",
		image: UkFlag,
	},
	{
		name: "German",
		shortName: "De",
		code: "de",
		image: DeFlag,
	},
];
