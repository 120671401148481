import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	BuildSubscriptionRequest,
	BuildSubscriptionResponse,
	BuildSubscriptionResponseSchema,
	BuildSubscriptionSchema,
	CreateSubscriptionRequest,
	CreateSubscriptionSchema,
	PaySubscriptionRequest,
	PaySubscriptionRequestSchema,
	PaySubscriptionResponse,
	PaySubscriptionResponseSchema,
	SaveCustomerAddressRequest,
	SaveCustomerAddressRequestSchema,
	SaveCustomerAddressResponseSchema,
} from "../../pages/Subscriptions/schema/create-subscription";
import {
	Subscription,
	SubscriptionSchema,
	SubscriptionsResponse,
	SubscriptionsResponseSchema,
} from "../../pages/Subscriptions/schema/get-subscriptions";

export const subscriptionApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptions: builder.query<SubscriptionsResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "subscriptions",
				params: {
					...(params && { ...params }),
				},
				responseSchema: SubscriptionsResponseSchema,
			}),
			providesTags: ["Subscriptions"],
		}),
		getSubscription: builder.query<Subscription, string>({
			query: (uuid) => ({
				url: `subscriptions/${uuid}`,
				responseSchema: SubscriptionSchema,
			}),
			providesTags: ["Subscriptions"],
		}),
		getSubscriptionBySecret: builder.query<PaySubscriptionResponse, string>({
			query: (secret) => ({
				url: `subscriptions/sc/${secret}`,
				responseSchema: PaySubscriptionResponseSchema,
			}),
			providesTags: ["Subscriptions"],
		}),
		createSubscription: builder.mutation<Partial<Subscription>, CreateSubscriptionRequest>({
			query: (body) => ({
				url: "subscriptions",
				method: "POST",
				body,
				requestSchema: CreateSubscriptionSchema,
				responseSchema: SubscriptionSchema,
			}),
			invalidatesTags: ["Subscriptions"],
		}),
		buildSubscription: builder.mutation<BuildSubscriptionResponse, BuildSubscriptionRequest>({
			query: (body) => ({
				url: "subscriptions/build",
				method: "POST",
				body,
				requestSchema: BuildSubscriptionSchema,
				responseSchema: BuildSubscriptionResponseSchema,
			}),
			invalidatesTags: ["Subscriptions"],
		}),
		paySubscription: builder.mutation<PaySubscriptionResponse, PaySubscriptionRequest>({
			query: (body) => ({
				url: "subscriptions/pay",
				method: "POST",
				body,
				requestSchema: PaySubscriptionRequestSchema,
				responseSchema: PaySubscriptionResponseSchema,
			}),
			invalidatesTags: ["Subscriptions"],
		}),
		saveCustomerAddress: builder.mutation<boolean, SaveCustomerAddressRequest>({
			query: ({ id, body }) => ({
				url: `subscriptions/${id}`,
				method: "PATCH",
				body,
				requestSchema: SaveCustomerAddressRequestSchema,
				responseSchema: SaveCustomerAddressResponseSchema,
			}),
		}),
		deleteSubscription: builder.mutation<Partial<Subscription>, number>({
			query: (id) => ({
				url: `subscriptions/${id}`,
				method: "DELETE",
				responseSchema: SubscriptionSchema,
			}),
			invalidatesTags: ["Subscriptions"],
		}),
	}),
});

export const {
	useGetSubscriptionsQuery,
	useGetSubscriptionQuery,
	useLazyGetSubscriptionBySecretQuery,
	useLazyGetSubscriptionQuery,
	useCreateSubscriptionMutation,
	useBuildSubscriptionMutation,
	usePaySubscriptionMutation,
	useDeleteSubscriptionMutation,
	useSaveCustomerAddressMutation,
} = subscriptionApi;
export const accountApiReducer = subscriptionApi.reducer;
export const accountApiMiddleware = subscriptionApi.middleware;
