import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FunctionComponent } from "react";

import { t } from "i18next";

import { TkDivider } from "@/components";
import { Subscription } from "@/pages/Private/pages/Subscriptions/schema/get-subscriptions";

interface ProductInfoProps {
	subscription: Subscription;
}

export const ProductInfo: FunctionComponent<ProductInfoProps> = ({ subscription }) => {
	const ts = (key: string) => t(`subscription.${key}`);
	const { product, onetimeAmount, installmentAmount, interval, cycles, employees } = subscription;

	const { name, description, image } = product;
	const overallPrice = (onetimeAmount || 0) + (installmentAmount || 0) * (cycles || 0);

	const toLocalNumber = (val: number) => {
		return val.toLocaleString("de-DE", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	};

	return (
		<Grid xs={6}>
			<Typography variant="h5">Zahlung an Exasize EOOD</Typography>

			<Typography variant="h3">{name}</Typography>
			<TkDivider />
			<Typography variant="body2">
				<div dangerouslySetInnerHTML={{ __html: description }} />
			</Typography>
			<TkDivider />

			<Typography variant="h4">Bestellübersicht</Typography>
			<Stack direction="column" gap={0}>
				<Stack direction="row" justifyContent={"space-between"} style={{ width: "100%" }}>
					<Typography variant="body2">
						{(installmentAmount || 0) > 0 ? "Anzahlung" : name}
					</Typography>
					{onetimeAmount ? (
						<Typography variant="body2">{toLocalNumber(onetimeAmount)} €</Typography>
					) : (
						<></>
					)}
				</Stack>
				{installmentAmount && interval && installmentAmount > 0 ? (
					<Stack direction="row" justifyContent={"space-between"} style={{ width: "100%" }}>
						<Typography variant="body2">
							{cycles} {ts(interval)} Raten zu je {toLocalNumber(installmentAmount)} €
						</Typography>
						<Typography variant="body2">
							{toLocalNumber(installmentAmount * (cycles || 0))} €
						</Typography>
					</Stack>
				) : (
					""
				)}
				{employees && employees > 0 ? (
					<>
						<Typography variant="caption">
							{employees === 1 ? (
								<>Du erhälst einen zusätzlichen Mitarbeiteraccount.</>
							) : (
								<>Du erhälst {employees} zusätzliche Mitarbeiteraccounts.</>
							)}
						</Typography>
					</>
				) : (
					""
				)}
				<TkDivider />
				<Stack direction="row" justifyContent={"space-between"} style={{ width: "100%" }}>
					<Typography variant="body2">Gesamt</Typography>
					<Typography variant="body2">{toLocalNumber(overallPrice)} €</Typography>
				</Stack>
				{image ? (
					<Stack alignItems={"center"} direction="column" gap={0}>
						<Box
							component="img"
							height={"auto"}
							src={image}
							style={{
								marginTop: "20px",
							}}
							width={"100%"}
						/>
					</Stack>
				) : (
					""
				)}
			</Stack>
		</Grid>
	);
};
