import { FunctionComponent, useEffect, useState } from "react";

import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLazyGetSubscriptionBySecretQuery } from "@/pages/Private/redux/subscription/subscription.api";
import { ROUTE_CONFIG } from "@/routes/config";
import { PaySubscriptionResponse } from "@/pages/Private/pages/Subscriptions/schema/create-subscription";

export const OrderInitialized: FunctionComponent = () => {
	const theme = useTheme();
	const [params] = useSearchParams();
	const [response, setResponse] = useState<PaySubscriptionResponse>();
	const navigate = useNavigate();
	const paymentIntentClientSecret = params.get("payment_intent_client_secret");
	const [getSubscription] = useLazyGetSubscriptionBySecretQuery();

	useEffect(() => {
		const initiatePayment = async (paymentIntentClientSecret: string) => {
			try {
				const subscriptionResponse = await getSubscription(paymentIntentClientSecret).unwrap();

				setResponse(subscriptionResponse);
			} catch (e) {
				navigate(ROUTE_CONFIG.ORDER_FAILED);
			}
		};

		if (paymentIntentClientSecret) {
			initiatePayment(paymentIntentClientSecret);
		}
	}, [navigate, getSubscription, paymentIntentClientSecret]);

	const onDownload = (url: string) => {
		const link = document.createElement("a");
		const fileNameArr = url.split("/");

		link.download = fileNameArr[-1];
		link.href = url;
		link.click();
	};

	return (
		<Container>
			<Stack alignItems={"center"} direction="column" justifyContent={"center"}>
				<Box mb={6} mt={6}>
					<FontAwesomeIcon color={theme.palette["success"].dark} icon={faCheckCircle} size="9x" />
				</Box>
				<Box justifyContent={"center"} mb={5} textAlign={"center"}>
					<Typography variant="h1">Bestellung erfolgreich aufgenommen!</Typography>
					<Typography variant="body1">
						Du kannst die Rechnung{" "}
						<Box
							component="span"
							style={{
								fontWeight: 700,
								cursor: "pointer",
								color: theme.palette["warning"].main,
							}}
							onClick={() => {
								response && onDownload(response.pdf);
							}}
						>
							hier
						</Box>{" "}
						herunterladen. Ebenfalls erhälst Du die Rechnung per E-Mail.
					</Typography>
				</Box>

				<Box justifyContent={"center"} textAlign={"center"}>
					<Typography variant="h3">Wichtig! Falls du überweisung gewählt hast</Typography>
					<Typography textAlign={"left"} variant="body1">
						1. Bitte nutze den Verwendungszweck der auf der Rechnung angegeben wurde.
					</Typography>
					<Typography textAlign={"left"} variant="body1">
						2. Bitte sende uns einen Screenshot der Überweisungsbestätigung an{" "}
						<a href="mailto:zahlungseingang@thorstenkreutz.com">
							zahlungseingang@thorstenkreutz.com
						</a>
						{"."}
					</Typography>
				</Box>
			</Stack>
		</Container>
	);
};
