import { Outlet } from "react-router-dom";
import { Box, Card, CardContent } from "@mui/material";

import HeroImage from "@assets/hero-illustration.svg";

export const AuthLayout = () => {
	return (
		<Box
			id="portal-auth"
			sx={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Box
				alt="Background image"
				component="img"
				src={HeroImage}
				sx={{
					width: "100%",
					position: "fixed",
					inset: 0,
				}}
			/>

			<Card
				elevation={16}
				sx={{
					mb: 4,
					position: "relative",
					width: "100%",
					maxWidth: "444px",
				}}
			>
				<CardContent>
					<Outlet />
				</CardContent>
			</Card>
		</Box>
	);
};
