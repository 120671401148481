import { z } from "zod";

export const CustomerSchema = z.object({
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
});

export const CustomersResponseSchema = z.array(CustomerSchema);

export type Customer = z.infer<typeof CustomerSchema>;

export type CustomersResponse = z.infer<typeof CustomersResponseSchema>;
