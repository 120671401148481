import { FunctionComponent, ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Stack, useTheme } from "@mui/system";

interface ModalProps {
	children: ReactNode;
	title: string;
	isOpened: boolean;
	handleClose: () => void;
	handleSave: () => void;
	isLoading?: boolean;
	submitButtonText?: string;
	submitButtonColor?: string;
}

export const Modal: FunctionComponent<ModalProps> = ({
	title,
	children,
	isOpened,
	handleClose,
	handleSave,
	submitButtonText,
	submitButtonColor,
	isLoading = false,
}) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Dialog fullWidth maxWidth="xs" open={isOpened} onClose={handleClose}>
			<Typography sx={{ pt: 4, px: 3, pb: 2 }} variant="h6">
				{title}
			</Typography>

			<DialogContent sx={{ pt: 2, pb: 4 }}>{children}</DialogContent>

			<DialogActions sx={{ px: 3, py: 2 }}>
				<Stack alignItems="center" direction="row" flexWrap="wrap" spacing={2}>
					<Button color="inherit" onClick={handleClose}>
						{t("basics.cancel")}
					</Button>

					<LoadingButton
						loading={isLoading}
						sx={{
							backgroundColor: submitButtonColor
								? theme.palette[submitButtonColor].dark
								: theme.palette.primary.main,
							"&:hover": {
								backgroundColor: submitButtonColor
									? theme.palette[submitButtonColor].darkest
									: theme.palette.primary.dark,
							},
						}}
						variant="contained"
						onClick={handleSave}
					>
						{submitButtonText ?? t("basics.saveChanges")}
					</LoadingButton>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
