export const ROUTE_CONFIG = {
	LOGIN: "/login",
	TFA: "/2fa",
	PLINK: "/plink",
	ORDER_COMPLETE: "/order-complete",
	SETUP_COMPLETE: "/setup-complete",
	ORDER_INITIALIZED: "/order-initialized",
	ORDER_FAILED: "/order-failed",
	LOGOUT: "/logout",
	FORGOT_PASSWORD: "/forgot-password",
	// APP: "/app",
	ACCOUNT: "/app/account",
	PRODUCTS: "/app/products",
	PRODUCT_LINKS: "/app/products/links",
	USERS: "/app/users",
	USER_ROLES: "/app/users/user-roles",
	SUBSCRIPTIONS: "/app/subscriptions",
};

export const PUBLIC_PAGES_AFTER_LOGIN = [
	ROUTE_CONFIG.PLINK,
	ROUTE_CONFIG.ORDER_COMPLETE,
	ROUTE_CONFIG.ORDER_FAILED,
	ROUTE_CONFIG.FORGOT_PASSWORD,
	ROUTE_CONFIG.SETUP_COMPLETE,
	ROUTE_CONFIG.ORDER_INITIALIZED,
];
