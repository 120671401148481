import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";

import { faGreaterThan } from "@fortawesome/pro-solid-svg-icons";

import { ErrorPageType } from "@/enum";

import { Icon } from "../Icon/Icon";
import NotFoundImg from "../../assets/images/error-pages/error-404.svg";
import UnauthorizeImg from "../../assets/images/error-pages/error-401.svg";
import ServerErrorImg from "../../assets/images/error-pages/error-500.svg";

interface ErrorPageProps {
	type: ErrorPageType;
}

export const ErrorPage: FunctionComponent<ErrorPageProps> = ({ type }) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`errorPages.${key}`);
	const navigate = useNavigate();

	let title = "";
	let imageSrc = "";

	switch (type) {
		case ErrorPageType.NotFound:
			title = ts("notFound");
			imageSrc = NotFoundImg;
			break;
		case ErrorPageType.Unauthorize:
			title = ts("unauthorize");
			imageSrc = UnauthorizeImg;
			break;
		case ErrorPageType.ServerError:
			title = ts("serverError");
			imageSrc = ServerErrorImg;
			break;
	}

	return (
		<Box
			sx={{
				padding: 2,
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Box alt="Error image" component="img" mb={3} src={imageSrc} />

			<Typography mb={1} variant="h5">
				{title}
			</Typography>

			<Typography color="text.secondary" mb={3}>
				{ts("description")}
			</Typography>

			<Button variant="contained" onClick={() => navigate("/")}>
				<Box mr={2}>{ts("buttonText")}</Box>
				<Icon icon={faGreaterThan} size="xs" />
			</Button>
		</Box>
	);
};
