import { configureStore, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

import { authReducer, authSlice } from "@/pages/Public/redux/auth.slice";

import { api } from "./api";
import { ROUTE_CONFIG } from "../routes/config";

const { LOGOUT } = ROUTE_CONFIG;

const rtkRejectQueryMiddleware: Middleware = () => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (action.payload.status === 401) {
			if (
				!["login", "authTwoFactorCode", "verifyTwoFactor", "disableTwoFactor"].includes(
					action.meta.arg.endpointName
				)
			) {
				window.location.href = LOGOUT;
			}
		}
	}

	return next(action);
};

export const store = configureStore({
	reducer: {
		[authSlice.name]: authReducer,
		[api.reducerPath]: api.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat(rtkRejectQueryMiddleware)
			.concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
