import { useState, MouseEvent } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	AppBar,
	Box,
	Button,
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Toolbar,
	Typography,
} from "@mui/material";
import { faAngleDown, faCheck, faUser } from "@fortawesome/pro-regular-svg-icons";

import TKCLogo from "@/assets/images/TKC_Shortlogo_digital_black.svg";

import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { languages } from "@/constants";
import { ROUTE_CONFIG } from "@/routes/config";
import { PermissionRoles } from "@/enum";

import { Avatar, Icon, PermissionCheck, Popup } from "..";

const { USERS, USER_ROLES, ACCOUNT, LOGOUT, PRODUCTS, SUBSCRIPTIONS } = ROUTE_CONFIG;

export const MainLayout = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { user } = useAppSelector(authSelector);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [showLanguagePopover, setShowLanguagePopover] = useState(false);
	const [showProfilePopover, setShowProfilePopover] = useState(false);

	const handleLanguagePopoverOpen = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setShowLanguagePopover(true);
	};

	const handleProfilePopoverOpen = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setShowProfilePopover(true);
	};

	const onClose = () => {
		setAnchorEl(null);
		setShowLanguagePopover(false);
		setShowProfilePopover(false);
	};

	const handleNavigate = (route: string) => {
		onClose();
		navigate(route);
	};

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
		onClose();
	};

	return (
		<Box
			sx={{
				width: "100%",
				minHeight: "100vh",
			}}
		>
			<AppBar
				color="transparent"
				position="static"
				style={{
					background:
						"radial-gradient(circle, #e6caa6, #9f785f 99%), linear-gradient(225deg, #aa8267 16%, #f1d3ac)",
				}}
			>
				<Toolbar sx={{ width: "100%" }}>
					<Stack
						alignItems={"center"}
						direction="row"
						justifyContent={"space-between"}
						sx={{ width: "100%" }}
					>
						<Stack direction="row" gap={1}>
							<Box component="img" height={"46px"} src={TKCLogo} />
							<PermissionCheck requiredPermissions={Object.values(PermissionRoles)}>
								<ListItem key={USERS} sx={{ cursor: "pointer" }} onClick={() => navigate(USERS)}>
									<Typography textAlign="center" variant={"subtitle1"}>
										{t("users.title")}
									</Typography>
								</ListItem>
							</PermissionCheck>

							<PermissionCheck requiredPermissions={[PermissionRoles.READ_ROLES]}>
								<ListItem
									key={USER_ROLES}
									sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
									onClick={() => navigate(USER_ROLES)}
								>
									<Typography textAlign="center" variant={"subtitle1"}>
										{t("userRoles.button")}
									</Typography>
								</ListItem>
							</PermissionCheck>

							<PermissionCheck requiredPermissions={[PermissionRoles.READ_PRODUCTS]}>
								<ListItem
									key={PRODUCTS}
									sx={{ cursor: "pointer" }}
									onClick={() => navigate(PRODUCTS)}
								>
									<Typography textAlign="center" variant={"subtitle1"}>
										{t("product.title")}
									</Typography>
								</ListItem>
							</PermissionCheck>

							<PermissionCheck requiredPermissions={[PermissionRoles.READ_SUBSCRIPTION]}>
								<ListItem
									key={SUBSCRIPTIONS}
									sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
									onClick={() => navigate(SUBSCRIPTIONS)}
								>
									<Typography textAlign="center" variant={"subtitle1"}>
										{t("subscription.title")}
									</Typography>
								</ListItem>
							</PermissionCheck>
						</Stack>

						<Stack
							alignItems="center"
							direction="row"
							gap={3}
							justifyContent="flex-end"
							px={3}
							py={1}
						>
							<Stack
								alignItems="center"
								direction="row"
								gap={1}
								sx={{ color: "neutral.500", cursor: "pointer" }}
								onClick={handleLanguagePopoverOpen}
							>
								<Box
									component="img"
									src={languages.find((l) => l.code === i18n.language)?.image}
									sx={{
										width: "1.5rem",
										height: "1.5rem",
										borderRadius: "100%",
										objectFit: "cover",
									}}
								/>
								<Typography color="text.primary" variant="body2">
									{languages.find((l) => l.code === i18n.language)?.shortName}
								</Typography>
								<Box
									sx={{
										transform: `rotate(${showLanguagePopover ? 180 : 0}deg)`,
										transition: "transform 0.2s",
									}}
								>
									<Icon icon={faAngleDown} />
								</Box>
							</Stack>

							<Popup anchorEl={anchorEl} showPopover={showLanguagePopover} onClose={onClose}>
								{languages.map((language) => (
									<ListItem
										key={`language-${language.code}`}
										disablePadding
										secondaryAction={
											language.code === i18n.language ? <Icon color="green" icon={faCheck} /> : null
										}
										onClick={() => handleLanguageChange(language.code)}
									>
										<ListItemButton
											sx={{
												px: 2,
												py: 1,
											}}
										>
											<ListItemIcon sx={{ height: 24 }}>
												<Box component="img" src={language.image} />
											</ListItemIcon>
											<ListItemText
												primary={<Typography variant="body1">{language.name}</Typography>}
											/>
										</ListItemButton>
									</ListItem>
								))}
							</Popup>

							<Stack
								alignItems="center"
								direction="row"
								gap={1.5}
								sx={{ color: "neutral.500", cursor: "pointer" }}
								onClick={handleProfilePopoverOpen}
							>
								<Avatar firstName={user?.firstName ?? ""} lastName={user?.lastName ?? ""} />
								<Box
									sx={{
										transform: `rotate(${showProfilePopover ? 180 : 0}deg)`,
										transition: "transform 0.2s",
									}}
								>
									<Icon icon={faAngleDown} />
								</Box>
							</Stack>

							<Popup anchorEl={anchorEl} showPopover={showProfilePopover} onClose={onClose}>
								<Box sx={{ p: 2 }}>
									<Typography variant="body1">
										{user?.firstName} {user?.lastName}
									</Typography>
									<Typography color="text.secondary" variant="body2">
										{user?.email}
									</Typography>
								</Box>
								<Divider />
								<Stack py={1.5}>
									<PermissionCheck
										requiredPermissions={[
											PermissionRoles.UPDATE_ME,
											PermissionRoles.GENERATE_2FA,
											PermissionRoles.CHANGE_PASSWORD,
										]}
									>
										<ListItemButton
											sx={{
												borderRadius: 1,
												px: 2,
												py: 0.5,
											}}
											onClick={() => handleNavigate(ACCOUNT)}
										>
											<ListItemIcon>
												<Icon icon={faUser} />
											</ListItemIcon>
											<ListItemText
												primary={<Typography variant="body1">{t("basics.account")}</Typography>}
											/>
										</ListItemButton>
									</PermissionCheck>
								</Stack>
								<Divider />
								<Button fullWidth color="inherit" onClick={() => handleNavigate(LOGOUT)}>
									{t("basics.logout")}
								</Button>
							</Popup>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>

			<Box px={7} py={5}>
				<Outlet />
			</Box>
		</Box>
	);
};
