import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTE_CONFIG } from "@/routes/config";
import { useAppSelector } from "@/redux/hooks";
import { ErrorPage } from "@/components";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { ErrorPageType } from "@/enum";

import { Products } from "../pages/Products/Products";

const { LOGIN } = ROUTE_CONFIG;

export const ProductRoutes: FunctionComponent = () => {
	const { user } = useAppSelector(authSelector);

	if (!user) {
		return <Navigate to={LOGIN} />;
	}

	return (
		<Routes>
			<Route element={<Products />} path="/" />
			<Route element={<ErrorPage type={ErrorPageType.NotFound} />} path="*" />
		</Routes>
	);
};
