export enum PermissionRoles {
	CREATE_ROLE = "CREATE_ROLE",
	READ_ROLES = "READ_ROLES",
	UPDATE_ROLE = "UPDATE_ROLE",
	DELETE_ROLE = "DELETE_ROLE",
	INVITE_USER = "INVITE_USER",
	GET_USER = "GET_USER",
	GET_ME = "GET_ME",
	DELETE_USER = "DELETE_USER",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	UPDATE_ME = "UPDATE_ME",
	UPDATE_USER = "UPDATE_USER",
	UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
	GENERATE_2FA = "GENERATE_2FA",
	GET_PERMISSION = "GET_PERMISSION",
	UPDATE_PERMISSION = "UPDATE_PERMISSION",
	MANAGE_CONFIGURATION = "MANAGE_CONFIGURATION",
	BLACKLIST_JWT = "BLACKLIST_JWT",
	LOGOUT = "LOGOUT",
	DISABLE_2FA = "DISABLE_2FA",
	VERIFY_2FA = "VERIFY_2FA",
	CREATE_PRODUCT = "CREATE_PRODUCT",
	UPDATE_PRODUCT = "UPDATE_PRODUCT",
	READ_PRODUCTS = "READ_PRODUCTS",
	DELETE_PRODUCT = "DELETE_PRODUCT",
	CREATE_PRODUCT_LINK = "CREATE_PRODUCT_LINK",
	GET_PRODUCT_LINKS = "GET_PRODUCT_LINKS",
	DELETE_PRODUCT_LINK = "DELETE_PRODUCT_LINK",
	GET_CUSTOMERS = "GET_CUSTOMERS",
	UPLOAD_FILES = "UPLOAD_FILES",
	CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION",
	UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION",
	READ_SUBSCRIPTION = "READ_SUBSCRIPTION",
	DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION",
}
