import { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Card,
	InputAdornment,
	OutlinedInput,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import {
	faTrashCan,
	faMagnifyingGlass,
	faCopy,
	faCheckCircle,
} from "@fortawesome/pro-light-svg-icons";

import {
	Icon,
	LoadingOverlay,
	Modal,
	PermissionCheck,
	TableColumn,
	TableComponent,
} from "@/components";
import { useSearch } from "@/utils";

import {
	useDeleteSubscriptionMutation,
	useGetSubscriptionsQuery,
} from "@/pages/Private/redux/subscription/subscription.api";

import { PermissionRoles } from "@/enum";

import { Subscription } from "../schema/get-subscriptions";

export const SubscriptionsTable: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`subscription.${key}`), [t]);

	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

	const { page, setPage, limit, setLimit, searchValue, setSearchValue } = useSearch();
	const { data: subscriptions } = useGetSubscriptionsQuery({
		page: page + 1,
		limit,
		searchValue,
	});
	const [deleteSubscription, { isLoading }] = useDeleteSubscriptionMutation();

	const handleCopyLink = (uuid: string) => {
		navigator.clipboard.writeText(`${window.location.origin}/plink/${uuid}`);
		setShowSnackbar(true);
	};

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setShowSnackbar(false);
	};

	const columns: TableColumn<Subscription>[] = useMemo(
		() => [
			{
				label: ts("name"),
				minWidth: 200,
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.name} ({row.product.name})
						</Typography>
					</Stack>
				),
			},
			{
				label: ts("asInvoice"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.invoice ? <Icon icon={faCheckCircle} size="xl" /> : ""}
						</Typography>
					</Stack>
				),
			},
			{
				label: ts("employees"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.employees}
						</Typography>
					</Stack>
				),
			},
			{
				label: ts("terms"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.onetimeAmount} + {row.installmentAmount} every {row.interval}
						</Typography>
					</Stack>
				),
			},
			{
				label: ts("cycles"),
				format: (row) => (
					<Stack alignItems="center" direction="row" spacing={1}>
						<Typography color="inherit" variant="body2">
							{row.cycles}
						</Typography>
					</Stack>
				),
			},
			{
				align: "right",
				label: "",
				minWidth: 20,
				format: (row) => (
					<Stack direction="row" justifyContent="flex-end" spacing={2}>
						<Box
							sx={{
								color: "neutral.500",
								"&:hover": {
									cursor: "pointer",
									color: "primary.main",
								},
							}}
							onClick={() => handleCopyLink(row.uuid)}
						>
							<Icon icon={faCopy} size="xl" />
						</Box>
						<PermissionCheck requiredPermissions={[PermissionRoles.DELETE_SUBSCRIPTION]}>
							<Box
								sx={{
									color: "neutral.500",
									"&:hover": {
										color: "primary.main",
										cursor: "pointer",
									},
								}}
								onClick={() => handleDeleteModal(row)}
							>
								<Icon icon={faTrashCan} size="xl" />
							</Box>
						</PermissionCheck>
					</Stack>
				),
			},
		],
		[ts]
	);

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setLimit(Number(event.target.value));
		setPage(0);
	};

	const handleDeleteModal = (row: Subscription) => {
		setShowDeleteModal(true);
		setSelectedSubscription(row);
	};

	const handleDeleteSubscription = async () => {
		try {
			await deleteSubscription(selectedSubscription?.id ?? 0).unwrap();
			setShowDeleteModal(false);
		} catch (err) {
			console.error(err);
		}
	};

	return !subscriptions ? (
		<LoadingOverlay />
	) : (
		<Card>
			<Stack direction="row" gap={2} px={3} py={2}>
				<OutlinedInput
					fullWidth
					placeholder={t("basics.search", { something: ts("search") })}
					startAdornment={
						<InputAdornment position="start">
							<Icon icon={faMagnifyingGlass} />
						</InputAdornment>
					}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</Stack>
			<TableComponent
				columns={columns}
				data={subscriptions?.data || []}
				handleChangeLimit={handleChangeRowsPerPage}
				handleChangePage={(_: unknown, page: number) => setPage(page)}
				itemCount={subscriptions?.meta.itemCount ?? 0}
				limit={limit}
				page={page}
			/>
			{showDeleteModal && (
				<Modal
					handleClose={() => setShowDeleteModal(false)}
					handleSave={handleDeleteSubscription}
					isLoading={isLoading}
					isOpened={showDeleteModal}
					submitButtonColor="error"
					submitButtonText={t("basics.delete")}
					title={ts("delete.title")}
				>
					<TextField
						fullWidth
						defaultValue={selectedSubscription?.name}
						inputProps={{ readOnly: true }}
						label={t("product.name")}
					/>
				</Modal>
			)}
			<Snackbar
				autoHideDuration={3000}
				message={ts("copied")}
				open={showSnackbar}
				onClose={handleClose}
			/>
		</Card>
	);
};
