import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ROUTE_CONFIG } from "@/routes/config";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { AuthError } from "@/components";

import { LoginType, LoginSchema } from "./schema/login";
import { useLoginMutation } from "../../redux/auth.api";

export const Login: FunctionComponent = () => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`login.${key}`);

	const navigate = useNavigate();

	const [login, { isLoading, error }] = useLoginMutation();

	const initialValues: LoginType = {
		email: "",
		password: "",
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginType>({
		defaultValues: initialValues,
		resolver: zodResolver(LoginSchema),
	});

	const formErrors = Object.values(errors).map((error) => error.message) as ERROR_TYPE[];

	const onSubmit = async (values: LoginType) => {
		try {
			await login(values).unwrap();
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Stack>
			<Typography mb={4} variant="h6">
				{ts("title")}
			</Typography>

			<Box component="form" onSubmit={handleSubmit(onSubmit)}>
				<TextField
					fullWidth
					autoComplete="email"
					label={t("basics.email")}
					sx={{ mb: 2 }}
					variant="filled"
					{...register("email")}
					type="email"
				/>
				<TextField
					fullWidth
					autoComplete="password"
					label={t("basics.password")}
					sx={{ mb: 2 }}
					variant="filled"
					{...register("password")}
					type="password"
				/>

				<Stack>
					<LoadingButton loading={isLoading} size="large" type="submit" variant="contained">
						{ts("loginCta")}
					</LoadingButton>

					<Link
						component="a"
						sx={{ mt: 2, textAlign: "center", cursor: "pointer" }}
						underline="hover"
						variant="subtitle2"
						onClick={() => navigate(ROUTE_CONFIG.FORGOT_PASSWORD)}
					>
						{ts("forgotPasswordCta")}
					</Link>
				</Stack>
			</Box>

			{getAllErrors(error, formErrors).length ? (
				<AuthError>{renderErrorMessages(getAllErrors(error, formErrors))}</AuthError>
			) : null}
		</Stack>
	);
};
