import { z } from "zod";

import { ERROR_TYPE } from "@/utils";
import { PASSWORD_REGEX } from "@/constants";

import { UpdateUserFormSchema } from "../../Users/schema/update-user";

const { PASSWORD_GUIDELINES, PASSWORD_MISMATCH, REQUIRED } = ERROR_TYPE;

export const UpdateFirstNameSchema = UpdateUserFormSchema.pick({ firstName: true });
export const UpdateLastNameSchema = UpdateUserFormSchema.pick({ lastName: true });

export const UpdateMeRequestSchema = UpdateUserFormSchema.omit({ email: true, roles: true })
	.extend({
		is2FAEnabled: z.boolean(),
	})
	.partial();

export const ChangePasswordSchema = z
	.object({
		oldPassword: z.string().min(1, { message: REQUIRED }).trim(),
		password: z
			.string()
			.min(1, { message: REQUIRED })
			.regex(PASSWORD_REGEX, PASSWORD_GUIDELINES)
			.trim(),
		confirmPassword: z.string().min(1, { message: REQUIRED }).trim(),
	})
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				code: "custom",
				path: ["confirmPassword"],
				message: PASSWORD_MISMATCH,
			});
		}
	});

export type UpdateFirstName = z.infer<typeof UpdateFirstNameSchema>;
export type UpdateLastName = z.infer<typeof UpdateLastNameSchema>;
export type UpdateMeBody = z.infer<typeof UpdateMeRequestSchema>;
export type ChangePassword = z.infer<typeof ChangePasswordSchema>;
