import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import {
	CreateProductRequest,
	CreateProductResponseSchema,
	UpdateProductBody,
} from "../../pages/Products/schema/create-product";
import {
	Product,
	ProductResponseSchema,
	ProductSchema,
	ProductsResponse,
} from "../../pages/Products/schema/get-products";

export const productApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProduct: builder.query<ProductsResponse, RequestPaginationParams>({
			query: (params) => ({
				url: "products",
				params: {
					...(params && { ...params }),
				},
				responseSchema: ProductResponseSchema,
			}),
			providesTags: ["Products"],
		}),
		createProduct: builder.mutation<Partial<Product>, CreateProductRequest>({
			query: (body) => ({
				url: "products",
				method: "POST",
				body,
				requestSchema: CreateProductResponseSchema,
				responseSchema: ProductSchema,
			}),
			invalidatesTags: ["Products"],
		}),
		updateProduct: builder.mutation<Partial<Product>, UpdateProductBody>({
			query: ({ body, id }) => ({
				url: `products/${id}`,
				method: "PATCH",
				body,
				responseSchema: ProductSchema,
			}),
			invalidatesTags: ["Products"],
		}),
		deleteProduct: builder.mutation<Partial<Product>, number>({
			query: (id) => ({
				url: `products/${id}`,
				method: "DELETE",
				responseSchema: ProductSchema,
			}),
			invalidatesTags: ["Products"],
		}),
	}),
});

export const {
	useGetProductQuery,
	useCreateProductMutation,
	useUpdateProductMutation,
	useDeleteProductMutation,
} = productApi;
export const accountApiReducer = productApi.reducer;
export const accountApiMiddleware = productApi.middleware;
