import { z } from "zod";

import { IntervalEnum } from "@/enum/subscription-enum";
import { MetaDataSchema } from "@/schemas/meta-data";
import { ERROR_TYPE } from "@/utils";

const { REQUIRED } = ERROR_TYPE;

export const InnerSubscriptionSchema = z.object({
	id: z.number(),
	uuid: z.string(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	onetimeAmount: z.number(),
	installmentAmount: z.number(),
	invoice: z.boolean(),
	cycles: z.number(),
	employees: z.number(),
	interval: z.nativeEnum(IntervalEnum),
});

export const ProductSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	description: z.string(),
	image: z.string().optional().nullable(),
	subscription: z.array(InnerSubscriptionSchema),
});

export const ProductResponseSchema = z.object({
	data: z.array(ProductSchema),
	meta: MetaDataSchema,
});

export type Product = z.infer<typeof ProductSchema>;

export type ProductsResponse = z.infer<typeof ProductResponseSchema>;
