import { FunctionComponent, ReactElement } from "react";

import { PermissionRoles } from "@/enum";
import { useAppSelector } from "@/redux/hooks";
import { authSelector } from "@/pages/Public/redux/auth.slice";
import { getAllUserPermissions } from "@/utils";

interface PermissionCheckProps {
	children: ReactElement;
	requiredPermissions: PermissionRoles[];
}

export const PermissionCheck: FunctionComponent<PermissionCheckProps> = ({
	children,
	requiredPermissions,
}) => {
	const { user } = useAppSelector(authSelector);

	const userPermissions = getAllUserPermissions(user?.roles ?? []);
	const hasPermission = requiredPermissions.every((permission) =>
		userPermissions.includes(permission)
	);

	return hasPermission ? children : null;
};
