import { z } from "zod";

import { FetchMeResponseSchema } from "@/pages/Public/pages/Login/schema/login";
import { ChipsListItemSchema } from "@/schemas/chips";
import { ERROR_TYPE } from "@/utils";

const { REQUIRED, INVALID_EMAIL } = ERROR_TYPE;

export const InviteRequestSchema = z.object({
	firstName: z.string().min(4, { message: REQUIRED }).trim(),
	lastName: z.string().min(4, { message: REQUIRED }).trim(),
	email: z.string().email(INVALID_EMAIL),
	roles: z.array(z.string()).min(1, { message: REQUIRED }),
});

export const CustomerSchema = z.object({
	firstName: z.string().min(4, { message: REQUIRED }).trim(),
	lastName: z.string().min(4, { message: REQUIRED }).trim(),
	email: z.string().email(INVALID_EMAIL),
});

export const InviteFormSchema = InviteRequestSchema.omit({ roles: true }).extend({
	roles: ChipsListItemSchema.array().min(1, { message: REQUIRED }),
});

export const InviteResponseSchema = FetchMeResponseSchema.partial();

export type InviteRequest = z.infer<typeof InviteRequestSchema>;
export type InviteForm = z.infer<typeof InviteFormSchema>;
export type Customer = z.infer<typeof CustomerSchema>;
